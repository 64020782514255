import { requests } from "../common/network";
import reviewsNodes from "../common/node";
import { $dqs, debounce } from "../common/utils";
import v from "../common/variable";
import { showOneInAll } from "./allinone-write-review";
let productSelector = null;

function showProductSelector() {
	if (productSelector) {
		productSelector.wrapper.style.display = "block";
	} else {
		let words = v.lang.all_in_one_from;
		const separate = 2;
		if (v.writeForm === separate) {
			words = v.lang.separate_review_form;
		}
		const mask = $dqs("#trustoo-mask");
		mask.insertAdjacentHTML(
			"beforeend",
			`<div id="tt-product-selector" class="trustoo-widget">
				<div id="tt-products-content">
				<div class="tt-window-close tt-write_review_close"></div>
				<div class="tt-header">
					<div class="tt-selector-title">${words.select_product_title}</div>
				</div>
				<div id="tt-product-search">
					<input type="text" class="tt-search" placeholder="${words.search_product_input_prompt}">
				</div>
				<div id="tt-product-list"></div>
				<div id="tt-loading">
					<div class="loader"></div>
				</div></div>
		</div>`
		);
		productSelector = new TTProductList();
		productSelector.init();
	}
}

// Sample product data
const initialProducts = [];

class TTProductList {
	constructor() {
		// DOM elements
		this.wrapper = document.getElementById("tt-product-selector");
		this.container = document.getElementById("tt-product-list");
		this.loadingEl = document.getElementById("tt-loading");
		this.searchInput = document.querySelector("#tt-product-search>.tt-search");

		// State
		this.allProducts = [...initialProducts]; // Store all products
		this.displayedProducts = [...initialProducts]; // Store filtered products
		this.page = 1;
		this.productCount = 0;
		this.perPage = 5;
		this.totalPage = 0;
		this.isLoading = false;
		this.hasMoreItems = true;
		this.searchTerm = "";
		this.observer = null;

		// Generate more mock products for demo
		// this.generateMoreProducts();

		// Initialize
	}

	generateMoreProducts() {
		// Generate more products for demo purposes
		const products = [];

		this.allProducts = [...this.allProducts, ...products];
	}

	init() {
		// Initial render
		this.renderProducts();

		// Set up event listeners
		this.setupScrollListener();
		this.setupSearchListener();
		this.setupCloseListener();
	}

	setupScrollListener() {
		// Set up intersection observer
		const options = {
			root: null, // viewport is used as root
			rootMargin: "100px", // Start loading a bit before the loading element is visible
			threshold: 0, // trigger as soon as any part is visible
		};

		// Create the observer
		this.observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting && !this.isLoading && this.hasMoreItems) {
					this.loadMoreProducts();
				}
			});
		}, options);

		// Start observing the loading element directly
		this.observer.observe(this.loadingEl);
	}
	setupCloseListener() {
		$dqs(".tt-window-close", this.wrapper).onclick = () => {
			this.closeSelector();
		};
	}
	closeSelector() {
		this.wrapper.style.display = "none";
		this.wrapper.parentNode.style.display = "none";
		document.body.classList.remove("trustoo-open");
	}
	setupSearchListener() {
		// Filter products as user types
		this.searchInput.addEventListener(
			"input",
			debounce(async e => {
				const isSearch = true;
				this.searchTerm = e.target.value.toLowerCase().trim();
				this.resetList();
				await this.renderProducts(isSearch);
				this.observer.observe(this.loadingEl);
			}, 400)
		);
	}

	resetList() {
		if (this.observer) {
			this.observer.disconnect();
		}
		this.container.innerHTML = "";
		this.page = 1;
		this.hasMoreItems = true;
		// Re-observe the loading element after resetting the list
	}

	filterProducts() {
		if (!this.searchTerm) {
			this.displayedProducts = [...this.allProducts];
			return;
		}

		this.displayedProducts = this.allProducts.filter(product =>
			product.title.toLowerCase().includes(this.searchTerm)
		);
	}

	getCurrentPageProducts() {
		return requests.getProducts(this.page, this.searchTerm);
	}

	async renderProducts() {
		const res = await this.getCurrentPageProducts();
		this.isLoading = false;
		if (res[0]) {
			return;
		}

		const data = res[1];
		if (!this.searchTerm && this.page === 1) {
			this.productCount = data.page.count;
		}
		const { cur_page, total_page } = data.page;
		const curPagePros = data.list;
		this.totalPage = total_page;
		if (curPagePros.length === 0 && this.page === 1) {
			if (this.productCount !== 0) {
				this.renderEmptyState();
			}
			this.hideLoading();
			return;
		}

		curPagePros.forEach((product, index) => {
			const productElement = this.createProductElement(product, index);
			this.container.appendChild(productElement);
		});

		// Check if we've displayed all products
		if (cur_page >= total_page) {
			this.hasMoreItems = false;
			this.hideLoading();
		} else {
			this.showLoading();
		}
	}

	createProductElement(product) {
		const productItem = document.createElement("div");
		productItem.className = "tt-product-item";
		productItem.dataset.id = product.id;
		productItem.dataset.id = product.id;

		const imageEl = document.createElement("img");
		imageEl.className = "tt-product-image";
		imageEl.src = product.image_src;
		imageEl.alt = product.title;
		imageEl.loading = "lazy";

		const detailsEl = document.createElement("div");
		detailsEl.className = "tt-product-details";

		const titleEl = document.createElement("div");
		titleEl.className = "tt-product-title";
		titleEl.textContent = product.title;

		detailsEl.appendChild(titleEl);
		productItem.appendChild(imageEl);
		productItem.appendChild(detailsEl);

		// Add click event
		productItem.addEventListener("click", () => {
			this.handleProductClick(product);
			const write = reviewsNodes.write.container;
			if (v.writeForm === 1) {
				showOneInAll("reviews");
			} else if (v.writeForm === 2) {
				write.parentNode.style.display = "block";
			}
			write.style.display = "block";
			this.wrapper.style.display = "none";
		});

		return productItem;
	}

	renderEmptyState() {
		const emptyEl = document.createElement("div");
		emptyEl.className = "tt-empty";
		emptyEl.textContent = v.lang.search_not_prod;
		this.container.appendChild(emptyEl);
	}

	loadMoreProducts() {
		if (this.isLoading || !this.hasMoreItems) return;
		this.isLoading = true;
		this.showLoading();

		// Simulate loading delay
		this.page++;
		this.renderProducts();
	}

	handleProductClick(data) {
		v.writeTo.productId = data.id;
		v.writeTo.productName = data.title;
		v.writeTo.prodImageUrl = data.image_src;
	}

	showLoading() {
		this.loadingEl.style.display = "flex";
	}

	hideLoading() {
		this.loadingEl.style.display = "none";
	}
}

export default showProductSelector;
