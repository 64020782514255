// 获取时间戳
function getTimeStamp() {
	return new Date().getTime();
}
// 颜色转换
function hexToRgba(hex, opacity) {
	if (opacity == 2) {
		opacity = 1;
	}
	return (
		"rgba(" +
		parseInt("0x" + hex.slice(1, 3)) +
		"," +
		parseInt("0x" + hex.slice(3, 5)) +
		"," +
		parseInt("0x" + hex.slice(5, 7)) +
		"," +
		opacity +
		")"
	);
}
// 添加toast
function setToast(msg, top = "10%", left = "50%") {
	var dom = document.createElement("div");
	dom.className = "toast";
	dom.style.cssText = `left:${left};top:${top}`;
	dom.innerHTML = msg;
	document.body.appendChild(dom);
	setTimeout(() => {
		document.querySelector(".toast").remove();
	}, 1500);
}
// 发布评论，按钮添加loading
function setLoading(dom, size = 20) {
	var loading = document.createElement("div");
	loading.className = "loader";
	loading.style.cssText = `display: inline-block;width:${size}px;height:${size}px`;
	dom.appendChild(loading);
}

//=======================================工具函数=========================================
//base64压缩
//Mark上传图片压缩
function dealImage(base, dom, res) {
	let image = new Image(); //设置一个image
	image.src = base; //动态加载
	image.onload = function () {
		let canvas = document.createElement("canvas"), //创建一个canvas元素
			context = canvas.getContext("2d"),
			originWidth = image.width /* 图片的宽度 */,
			originHeight = image.height /* 图片的高度 */,
			data = ""; //存储压缩后的图片
		if (originWidth < originHeight) {
			dom.style.backgroundSize = "auto 100%";
		}
		const quality = 0.8;
		// 设置最大尺寸限制，将所有图片都压缩到小于1m
		const maxWidth = 1200,
			maxHeight = 1200;
		// 需要压缩的目标尺寸
		let targetWidth = originWidth,
			targetHeight = originHeight;
		// 等比例计算超过最大限制时缩放后的图片尺寸
		if (originWidth > maxWidth || originHeight > maxHeight) {
			if (originWidth / originHeight > 1) {
				// 宽图片
				targetWidth = maxWidth;
				targetHeight = Math.round(maxWidth * (originHeight / originWidth));
			} else {
				// 高图片
				targetHeight = maxHeight;
				targetWidth = Math.round(maxHeight * (originWidth / originHeight));
			}
		}
		canvas.width = targetWidth;
		canvas.height = targetHeight; //设置绘图的高度
		context.drawImage(image, 0, 0, targetWidth, targetHeight);
		//使用toDataURL将canvas上的图片转换为base64格式
		data = canvas.toDataURL("image/jpg", quality);
		dom.style.backgroundImage = `url(${data})`;
		res(data);
	};
}

//返回窗口宽度
function getWindowWidth() {
	return window.innerWidth;
}

//Mark图片预加载
// var preLoadCount = 0,
function imgLoadLimit(urlArr, limit) {
	const urlArrCy = [...urlArr];

	if (urlArrCy.length <= limit) {
		const promiseArr = urlArrCy.map(url => {
			var pro = imgLoad(url);
			pro.id = url;
			return pro;
		});
		return Promise.all(promiseArr);
	}

	const promiseArr = urlArrCy.splice(0, limit).map(url => {
		var pro = imgLoad(url);
		pro.url = url;
		return pro;
	});
	urlArrCy
		.reduce(
			(prePromise, curUrl) =>
				prePromise
					.then(() => Promise.race(promiseArr))
					.then(resUrl => {
						let promiseIndex = promiseArr.findIndex(item => item.url == resUrl);
						promiseArr.splice(promiseIndex, 1);
						var pro = imgLoad(curUrl);
						pro.url = curUrl;
						promiseArr.push(pro);
					}),
			Promise.resolve()
		)
		.then(() => Promise.all(promiseArr));
}

// 图片上传
function imgLoad(url) {
	return new Promise((res, rej) => {
		var img = new Image();
		img.url = url;
		img.onerror = () => {
			rej();
			//   console.log(img.id,'图片加载失败');
		};
		img.onload = () => {
			//   console.log(img.id, '图片加载成功');
			res(img);
		};
		img.src = url;
	});
}

function getObjectURL(file) {
	var url = null;
	// 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
	if (window.createObjectURL != undefined) {
		// basic
		url = window.createObjectURL(file);
	} else if (window.URL != undefined) {
		// mozilla(firefox)
		url = window.URL.createObjectURL(file);
	} else if (window.webkitURL != undefined) {
		// webkit or chrome
		url = window.webkitURL.createObjectURL(file);
	}
	return url;
}

function setObserve(node, config, callback, v) {
	// 选择需要观察变动的节点
	// const node = document.body

	// 观察器的配置（需要观察什么变动）
	// const config = { attributes: true, childList: true, subtree: true, characterData: true };
	// 当观察到变动时执行的回调函数
	// const callback = function (mutationsList, observer) {
	// };

	// 创建一个观察器实例并传入回调函数
	v.observer = new MutationObserver(callback);

	// 以上述配置开始观察目标节点
	v.observer.observe(node, config);
}

//锚点跳转
function scrollTo(node, mode = "smooth") {
	node.scrollIntoView({
		behavior: mode,
		block: "start",
	});
}
// 获取手机系统
function getMobileSystem() {
	var u = navigator.userAgent;
	var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
	var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
	if (isAndroid) {
		return "android";
	}
	if (isiOS) {
		return "ios";
	}
}

/**
 * document.querySelector
 * @param {number} selector css选择器
 * @param {HTMLElement} context 上下文
 * @returns {HTMLElement} 选中的单个元素
 */
function $dqs(selector, context) {
	let node;
	if (context) {
		node = context.querySelector(selector);
	} else {
		node = document.querySelector(selector);
	}
	return node;
}

/**
 * document.querySelectorAll
 * @param {number} selector css选择器
 * @param {HTMLElement} context 上下文
 * @returns {HTMLElement} 选中元素集合
 */
function $dqsa(selector, context) {
	let nodes;
	if (context) {
		nodes = context.querySelectorAll(selector);
	} else {
		nodes = document.querySelectorAll(selector);
	}
	return nodes;
}
//是否为移动端 返回布尔值
// function isMobile() {
//   return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? true : false
// }

/**
 * 复制到剪切板
 * @param {string} text 字符串
 * @param {function} callback 成功回调函数
 */
function copyToClipboard(text, callback) {
	navigator.clipboard.writeText(text).then(() => {
		callback();
	});
}

function isMobile() {
	let ua = navigator.userAgent,
		isAndroid = /(?:Android)/.test(ua),
		isFireFox = /(?:Firefox)/.test(ua),
		isTablet =
			/(?:iPad|PlayBook)/.test(ua) ||
			(isAndroid && !/(?:Mobile)/.test(ua)) ||
			(isFireFox && /(?:Tablet)/.test(ua)),
		isPhone = /(?:iPhone)/.test(ua) && !isTablet;
	return isAndroid || isPhone;
}

// 防抖
function debounce(fn, delay) {
	var timeout = null;
	return function (...args) {
		if (timeout) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			fn(...args);
		}, delay);
	};
}

function setCopy(node) {
	// var copyDOM = $dqs('#discount .body-code')  //需要复制文字的节点
	var range = document.createRange(); //创建一个range
	window.getSelection().removeAllRanges();
	range.selectNode(node); // 选中需要复制的节点
	window.getSelection().addRange(range); // 执行选中元素
	var successful = document.execCommand("copy"); // 执行 copy 操作
	if (successful) {
		setToast("Copy successfully");
	} else {
	}
	// 移除选中的元素
	window.getSelection().removeAllRanges();
}

// promise延时
function setDelay(delay) {
	return new Promise(res => {
		setTimeout(() => {
			res();
		}, delay);
	});
}

function gsSession(method, name, data) {
	if (method === "set") {
		try {
			sessionStorage.setItem(name, JSON.stringify(data));
		} catch (error) {}
	} else {
		return JSON.parse(sessionStorage.getItem(name));
	}
}
function gsLocal(method, name, data) {
	if (method === "set") {
		localStorage.setItem(name, JSON.stringify(data));
	} else {
		return JSON.parse(localStorage.getItem(name));
	}
}

// 获取ip
function returnCitySN(v) {
	fetch("https://www.cloudflare.com/cdn-cgi/trace")
		.then(res => {
			return res.text();
		})
		.then(data => {
			data = data.split("\n");
			v.ip = data[2].split("=")[1];
			v.loc = data[9].split("=")[1];
		});
}

//图片上传
function get_suffix(filename) {
	let pos = filename.lastIndexOf(".");
	let suffix = "";
	if (pos != -1) {
		suffix = filename.substring(pos);
	}
	let g_object_name = random_string(10) + suffix;
	return g_object_name;
}
// iso随机数生成
function random_string(len) {
	len = len || 32;
	var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	var maxPos = chars.length;
	var pwd = "";
	for (var i = 0; i < len; i++) {
		pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
}

// 浏览器是否支持支持Web
function isWebPSupported() {
	return new Promise(resolve => {
		const webpImage = new Image();

		// 图片加载成功表示支持WebP
		webpImage.onload = function () {
			resolve(webpImage.width === 1);
		};

		// 图片加载失败表示不支持WebP
		webpImage.onerror = function () {
			resolve(false);
		};

		// 一个1x1像素的WebP测试图片（Base64编码）
		webpImage.src =
			"data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
	});
}

export {
	getTimeStamp,
	hexToRgba,
	setToast,
	setLoading,
	dealImage,
	getWindowWidth,
	imgLoadLimit,
	imgLoad,
	scrollTo,
	$dqs,
	$dqsa,
	isMobile,
	debounce,
	returnCitySN,
	getMobileSystem,
	setObserve,
	gsSession,
	gsLocal,
	setDelay,
	get_suffix,
	setCopy,
	getObjectURL,
	isWebPSupported,
	copyToClipboard,
};
