import v from "../common/variable";
import { $dqs, scrollTo } from "../common/utils";
import { sgPage, Pager, updateNumberPagination } from "./review-pager";
import { setReviewBody } from "./reviews-body";
import { setQAList } from "../QA";

//Mark 构造评论底部 分页器 待废弃
function setReviewFooter(reviewsDom) {
	if (!v.canInsertPagination) {
		return;
	}
	const { lang, isRevPage, isWdoRevOpen, isGrid, userSetting } = v;
	const key = v.isWdoRevOpen ? "allReviews" : "reviews";
	const pageCount = v.pageInfo[key].total;

	const paginationType = userSetting.review_pagination_type;

	if (paginationType === 1 && !isWdoRevOpen && !isRevPage) {
		let pager = new Pager(
			$dqs("#reviews-footer", reviewsDom),
			"beforeend",
			5,
			pageCount,
			setReviewBody,
			"reviews"
		);
		if (v.pageInfo.reviews.total === 0) {
		}
		if (isWdoRevOpen) v.wdoPager = pager;
		else v.pager = pager;
	} else if (
		paginationType === 2 &&
		pageCount > 1 &&
		!isRevPage &&
		!isWdoRevOpen
	) {
		const html = `<button class="more-review-btn" type="button"
     style="display:${pageCount === 1 ? "none" : "block"}">${
			lang.more
		}</button>`;
		const footer = $dqs("#reviews-footer", reviewsDom);
		footer.insertAdjacentHTML("beforeend", html);
		$dqs(".more-review-btn", reviewsDom).onclick = function (e) {
			const increasePageNum = 1;
			e.target.style.display = "none";
			$dqs(".loader", e.target.parentNode).style.display = "block";
			sgPage(increasePageNum);
			if (v.showType === "QA") {
				setQAList();
			} else {
				setReviewBody();
			}
		};
	}
}

// Mark 设置评论区分页
function setReviewPagination(paginationType, reviewsDom, page, isFilter) {
	const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;
	const pageCount = pageInfo[v.showType].total;
	const pageNumberPagination = 1;
	//  parseInt(reviewsDom.getAttribute("review-page-count"));

	const revFooter = $dqs("#reviews-footer", reviewsDom);
	// if (pageCount > 1 && page === 1) {
	// 	revFooter.style.display = "block";
	// } else if (pageCount <= 1) {
	// 	revFooter.style.display = "none";
	// }

	// if (v.showType === "shopReviews" && pageInfo[v.showType].curPage === 1) {
	// 	if (paginationType === pageNumberPagination) {
	// 		updatePagination();
	// 	}
	// }

	const loading = $dqs(".loader", reviewsDom);
	if (loading && loading.style.display === "block") {
		loading.style.display = "none";
	}
	v.allRevLoadLock = false;

	if (!v.isRevPage && !v.isWdoRevOpen) {
		if (paginationType === 1) {
			if (!v.isFirstPageRender) {
				if (v.isList) {
					scrollTo(reviewsDom);
				} else {
					scrollTo(reviewsDom, "auto");
				}
			}
			if (isFilter) {
				updateNumberPagination();
				// if (pageCount > 1) {
				// 	const controlList = $dqsa(".page-control-item", revFooter);
				// 	$dqs(".pre-page", revFooter).style.display = "none";
				// 	$dqs(".next-page", revFooter).style.display = "flex";
				// 	for (let i = 0; i < 5; i++) {
				// 		const it = controlList[i];
				// 		if (i < pageCount) {
				// 			it.style.display = "inline-block";
				// 		} else {
				// 			it.style.display = "none";
				// 		}
				// 	}
				// }
			}
		} else {
			// const gridMoreBtn = $dqs(".more-review-btn", reviewsDom);
			// if (gridMoreBtn) {
			// 	if (page < pageCount && gridMoreBtn) {
			// 		gridMoreBtn.style.display = "block";
			// 	}
			// }
			updateMoreBtnDisplay();
		}
	}
}
function updateMoreBtnDisplay() {
	const pageCount = v.pageInfo[v.showType].total;
	const page = v.pageInfo[v.showType].curPage;
	const gridMoreBtn = $dqs(".more-review-btn", v.reviewsDom);
	if (gridMoreBtn) {
		if (page < pageCount) {
			gridMoreBtn.style.display = "block";
		} else {
			gridMoreBtn.style.display = "none";
		}
	}
}

function updatePagination() {
	const { lang, isRevPage, isWdoRevOpen } = v;
	const scrollLoading = isWdoRevOpen || isRevPage;
	if (scrollLoading) {
		return;
	}
	const pageNumberPagination = 1,
		morePagination = 2;
	const paginationType = v.userSetting.review_pagination_type;
	const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;
	const pageCount = pageInfo[v.showType].total;
	if (!v.pager && pageCount !== 0) {
		v.reviewsDom.insertAdjacentHTML(
			"beforeend",
			`<div id="reviews-footer"></div>`
		);
		const footer = $dqs("#reviews-footer", v.reviewsDom);
		if (paginationType === pageNumberPagination) {
			v.pager = new Pager(
				footer,
				"beforeend",
				5,
				pageCount,
				setReviewBody,
				"reviews"
			);
		} else if (paginationType === morePagination) {
			const html = `<div class="loader" style="display:none;"></div>
			<button class="more-review-btn" type="button"
			 style="display:${pageCount === 1 ? "none" : "block"}">${lang.more}</button>`;
			footer.insertAdjacentHTML("beforeend", html);
			v.pager = { el: $dqs(".more-review-btn", footer) };
			v.pager.el.onclick = function (e) {
				const increasePageNum = 1;
				e.target.style.display = "none";
				$dqs(".loader", e.target.parentNode).style.display = "block";
				sgPage(increasePageNum);
				if (v.showType === "QA") {
					setQAList();
				} else {
					setReviewBody();
				}
			};
		}
	}
	if (paginationType === pageNumberPagination) {
		updateNumberPagination();
	} else if (paginationType === morePagination) {
		updateMoreBtnDisplay();
	}
}

export {
	setReviewFooter,
	setReviewPagination,
	updateMoreBtnDisplay,
	updatePagination,
};
