import v from "../common/variable";
import { $dqs, $dqsa, scrollTo } from "../common/utils";
import { sgPage, Pager } from "./review-pager";
import { setReviewBody } from "./reviews-body";

//Mark 构造评论底部 分页器
function setReviewFooter(reviewsDom, pageCount) {
	const { lang, isRevPage, isWdoRevOpen, isGrid, userSetting } = v;

	const revPageType = userSetting.review_pagination_type;
	if (revPageType === 1 && !isWdoRevOpen && !isRevPage) {
		if (pageCount > 1) {
			let pager = new Pager(
				$dqs("#reviews-footer", reviewsDom),
				"beforeend",
				5,
				pageCount,
				setReviewBody,
				"reviews"
			);
			if (isWdoRevOpen) v.wdoPager = pager;
			else v.pager = pager;
		}
	} else if (
		revPageType === 2 &&
		pageCount > 1 &&
		!isRevPage &&
		!isWdoRevOpen
	) {
		const html = `<button class="more-review-btn" type="button"
     style="display:${isGrid ? "none" : "block"}">${lang.more}</button>`;
		const footer = $dqs("#reviews-footer", reviewsDom);
		footer.insertAdjacentHTML("beforeend", html);
		$dqs(".more-review-btn", reviewsDom).onclick = function (e) {
			e.target.style.display = "none";
			$dqs(".loader", e.target.parentNode).style.display = "block";
			sgPage(1);
			setReviewBody();
		};
	}
}

// Mark 设置评论区分页
function setReviewPagination(revPageType, reviewsDom, page, isFilter) {
	const pageCount = parseInt(reviewsDom.getAttribute("review-page-count"));

	const revFooter = $dqs("#reviews-footer", reviewsDom);
	// if (pageCount > 1 && page === 1) {
	// 	revFooter.style.display = "block";
	// } else if (pageCount <= 1) {
	// 	revFooter.style.display = "none";
	// }

	const loading = $dqs(".loader", reviewsDom);
	if (loading.style.display === "block") {
		loading.style.display = "none";
	}

	v.allRevLoadLock = false;

	if (!v.isRevPage && !v.isWdoRevOpen) {
		if (revPageType === 1) {
			if (!v.isFirstPageRender) {
				if (v.isList) {
					scrollTo(reviewsDom);
				} else {
					scrollTo(reviewsDom, "auto");
				}
			}
			if (isFilter) {
				if (pageCount > 1) {
					const controlList = $dqsa(".page-control-item", revFooter);
					$dqs(".pre-page", revFooter).style.display = "none";
					$dqs(".next-page", revFooter).style.display = "flex";
					for (let i = 0; i < 5; i++) {
						const it = controlList[i];
						if (i < pageCount) {
							it.style.display = "inline-block";
						} else {
							it.style.display = "none";
						}
					}
				}
			}
		} else {
			const gridMoreBtn = $dqs(".more-review-btn", reviewsDom);
			if (page < pageCount) {
				gridMoreBtn.style.display = "block";
			}
		}
	}
}

export { setReviewFooter, setReviewPagination };
