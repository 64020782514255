/**
 * @typedef {import("../../js/types").ReviewsOptions} UserSettings
 */
const variable = {
	source: "reviews",

	staticBaseUrl: "",
	//==========:嵌入星星相关================
	//内容宽度
	wrapWid: 0,

	// 是否是无评论区
	isNullRev: false,

	//嵌入星星目标
	proTarget: null,
	collTarget: [],
	ratingIconSvg: "",
	badgeIconSvg: "",

	//星星排列方向
	themeStyle: "",
	// 客户端ip
	ip: "",
	// 是否首次运行（没有缓存）
	isFirstExecute: false,

	//各页面判断
	isCollPage: false,
	isProPage: false,
	isHomePage: false,
	// 是否为购物车页
	isCartPage: false,

	//数据更新判断，更新进行接口请求
	isSettingUpdate: false,
	isProRatingUpdate: false,
	isReviewsUpdate: false,

	// 浏览器是否支持webp
	isWebPSupported: true,

	// 评论区类名头缀
	revIdBase: "vstar-",

	isOverwriteOldScripts: false,

	// 是否已记录pv
	isRecordPV: false,
	// 是否已加购物车行为
	isRecordCart: false,

	// 购物车监听器
	cartWatcher: null,

	RevIntersection: null,

	//==========评论列表相关 product==========//

	//页面星星是否展示
	isCollOpen: false,
	isProOpen: false,
	isRevOpen: false,
	// 是否第一次渲染评论区的第一页评论
	isFirstPageRender: true,

	// 买家秀展示类型
	buyersShowType: 2,
	// 是否存在买家秀列表
	isInsertBS: false,
	// 混合评论区
	isMixReview: false,
	// 第几页评论数据
	page: 0,
	// 弹窗评论区评论第一页是否已渲染
	isWdoRevFirstPageRendered: false,
	// 每页评论数据
	reviewsList: [],
	//评论页数标记
	pageRecord: [],
	// 评论区评论列表的dom元素
	reviewsBody: null,
	// 评论区区的dom元素
	reviewsDom: null,

	// 评论列表请求参数（用于更新缓存）
	revReqParam: "",

	productHandle: "",
	//是否为移动端

	//用户配置
	/** @type {UserSettings} */
	userSetting: {},
	// 展示的什么类型，1为评论/2为QA
	showType: "reviews",

	//============评论区布局相关============//

	//评论布局
	layoutClass: "",
	//评论区布局每次请求的评论数
	layoutReviewCount: 0,
	//窗口大小
	reviewsWidth: 0,
	reviewsEllipsis: "count", // 多行省略方式 row/count
	//评论开始省略的行数
	reviewsEllipsisRow: 4,
	//已上传图片数量
	imgUploadedCount: 0,
	//上传图片标识
	uploadedIndex: 0,
	//grid布局卡片宽度
	gridCardWidth: 0,
	// 弹窗评论区grid布局卡片宽度
	wdoGridCardWidth: 0,

	// list评论区评论节点显示索引
	listDisIndex: 0,
	// list评论区评论节点数量
	listNodeCount: 0,

	//评论列表图片预览器
	viewer: null,

	//预加载图片数组
	origImg: [],
	// list缩略图片数组
	thumbImg: [],

	// 赞踩评论提交时锁定
	likeRevLock: false,

	//多语言
	lang: {},

	//瀑布流相关
	column: 0,
	minHeight: 0,
	// 瀑布流每行高度、每行前高度、卡片宽度、卡片数量
	//评分筛选 0为全部，1-5为对应分值的评论
	reviews: {
		columnHighs: [],
		shopReviewsHighs: [],
		preColHighs: [],
		preShopReviewsHighs: [],
		cardWid: 0,
		cardCount: 0,
		ratingFilter: 0,
	},

	// 买家秀嵌入选择器
	bsInsertSelector: null,
	albumCount: 8,
	albumSortType: "commented-at-descending",
	// 是否确定会嵌入评论区
	isInsertRev: false,

	// 网络css是否已加载
	isCssLoaded: false,
	// 内联css
	globalStyle: null,
	//请求错误信息
	reqErr: null,
	pageInfo: {
		reviews: {
			total: 0,
			curPage: 1,
			activeIndex: 0,
			pageNumbers: [],
			previousDisplay: "",
			nextDisplay: "",
		},

		// shopReviews{			total:0
		// 	pageNumbers:[]},QA:{
		// 		total:0
		// 		pageNumbers:[]
		// }
	},
	popupPageInfo: {
		reviews: {
			total: 0,
			curPage: 1,
			activeIndex: 0,
			pageNumbers: [],
			previousDisplay: "",
			nextDisplay: "",
		},
	},

	//Mark 全局变量声明

	// ============产品页=============
	isProHasHalfStar: false,
	pageCount: 0,
	//产品总评
	ratingData: {},
	//全产品总评
	allRatingData: { total_reviews: -1 },

	//=======产品列表相关 collection======

	// 从集合页产品页列表，目标元素向上找a标签次数
	searchACount: 6,
	// 当前嵌入目前索引
	collTarInx: 0,
	// 是否是shopify官方以外的主题
	isOtherTheme: false,
	// 是否完成嵌入
	isCollFinished: false,
	//监听排序
	observer: null,
	// 没有星级评价时设置监听（例如懒加载的列表）
	isSetObserver: false,

	// 显示监听 用于滚动加载监听
	iObserver: null,

	// 当前请求产品的集合的数据数组
	curCollArr: [],

	// ================home页==========
	// 首页星星嵌入是否开启
	isHomeRatingOpen: false,

	// 停止全部评论区的滚动加载
	allRevLoadLock: false,

	//============写评论弹框相关============
	mediaUrl: [],
	// 写评论间隔锁
	isSendLock: false,
	// 写评论弹窗设定评分默认值
	ratingVal: 0,
	//上传图片需要的相关信息
	uploadImgInfo: {},
	// 上传文件类型 1：图片 2：视频
	uploadType: 1,

	// 评论内容限制的字数
	newRevCharLimit: 2000,
	isLoginWrite: false,
	copyText: "",
	writePopupEmbedded: false,
	writeTo: {
		/**
		 *  @property {'shop'|'product'} type - 类型
		 *  @property {string} productId - 产品ID
		 *  @property {string} prodImageUrl - 产品ID
		 *  @property {string} productName - 产品ID
		 */
		type: "normal",
		productId: "",
		prodImageUrl: "",
		productName: "",
	},

	//============弹窗评论区相关============
	// 瀑布流每行高度、每行前高度、卡片宽度、卡片数量
	wdoRev: {
		columnHighs: [],
		shopReviewsHighs: [],
		preColHighs: [],
		preShopReviewsHighs: [],
		cardWid: 0,
		cardCount: 0,
		ratingFilter: 0,
	},
	//是否开启弹窗评论区
	isActWdoRev: false,
	// 弹窗评论区是否显示
	isWdoRevOpen: false,
	// 当前显示的评论区的标识 normal标准评论区/popup弹窗评论区
	reviewsKey: "normal",
	// 弹窗评论区是否已嵌入
	isWdoRevExist: false,
	// 弹窗评论区的dom元素
	wdoRevDom: false,
	wdoRevBody: null,
	isLinkReqRev: false,
	writeForm: 0,
	writeRestriction: 1,

	//pagefly集成

	//是否存在py星星控件
	hasPyRating: false,

	// 产品页评论区产品组是否显示关联产品
	showRelatedPros: false,
	// 是否嵌入分页器
	canInsertPagination: false,

	//===========评论详情弹窗相关===========
	revDetailInfo: {
		page: 0, // 评论属于第几页评论
		no: 0, // 评论卡片的序号
		imageIndex: 0, // 当前显示图片的索引
	},

	// QA相关
	isQAInserted: false,
	qsPageNum: 0,
};

export default variable;

// module.exports = variable
