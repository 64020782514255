import v from "../common/variable";

function initial() {
	const u = {};
	switch (v.shop_id) {
		//Mark 产品星星控件嵌入配对
		//Mark 集合星星控件嵌入配对
		case 55363502159: {
			if (v.isHomePage)
				u.customCollTarSel =
					".h1.product__title,.card__heading.h5>.full-unstyled-link";
			break;
		}
		case 64402030811: {
			v.isHomePage &&
				(u.customCollTarSel =
					'.glide-wrapper.glider-slide [data-product-type="title"]');
			break;
		}
		case 59471560866: {
			u.homeStarDelay = 2000;
			v.isHomePage && (u.customCollTarSel = ".prod-thumb-title-color>a");
			break;
		}
	}
	return u;
}

export default initial;
