import { requests } from "../../common/network";
import { getTotalStarString } from "../../page/page-common";
import { $dqs } from "../../swiper-reviews";
import u from "../../user_need/user_need";

const curPage = 1,
	pageSize = 6;
const toggleButtonHTML = `<div class="tt-bs-button"><svg xmlns="http://www.w3.org/2000/svg" width="6" height="10.8" viewBox="0 0 6 12" fill="none">
<path d="M5.3573 11.4016C5.19273 11.4016 5.02813 11.3385 4.90256 11.2119L0.188369 6.46006C-0.0627898 6.20734 -0.0627898 5.7969 0.188369 5.54373L4.90256 0.791437C5.15372 0.538271 5.56047 0.538271 5.81163 0.791437C6.06279 1.04416 6.06279 1.4546 5.81163 1.70777L1.55218 6.00166L5.81163 10.2951C6.06279 10.5483 6.06279 10.9583 5.81163 11.2115C5.68648 11.3385 5.52188 11.4016 5.3573 11.4016Z" fill="black"/>
</svg></div>
`;
export default function insertTextCarousel(wrapper) {
	const promises = [
		requests.getProRevList(
			"commented-at-descending",
			5,
			curPage,
			pageSize,
			false,
			false,
			null,
			true
		),
		requestSwiper(),
	];
	Promise.all(promises).then(res => {
		const reviewsRes = res[0];
		if (reviewsRes[0]) {
			throw 11;
		}

		wrapper.insertAdjacentHTML("beforeend", `<div id="tt-bs-carousel"></div>`);
		const carouselWrapper = $dqs("#tt-bs-carousel", wrapper);
		const reviewsData = reviewsRes[1];
		const list = reviewsData.list;
		if (list.length === 0) {
			return;
		}

		const isHasBtn = list.length > 1;
		const buttonHtml = isHasBtn ? toggleButtonHTML : "";
		const reviewsHtml = list.reduce((pre, review) => {
			return (
				pre +
				`
    <div class="swiper-slide tt-bs-card">
      <div class="tt-bs-first-row">
        <div class="tt-bs-author">
        ${review.author}
        </div>
        <div class="star vstar-star">
          ${getTotalStarString(review.star)}
        </div>
      </div> 
      <div class="tt-bs-text">
      ${review.content}
      </div>
    </div>
    `
			);
		}, "");
		carouselWrapper.insertAdjacentHTML(
			"afterbegin",
			`${buttonHtml}<div class="tt-swiper-container">
			<div class="swiper-wrapper" id="tt-bs-text-container" navigation="false">
        ${reviewsHtml}</div></div>${buttonHtml}`
		);
		initSwiper($dqs(".tt-swiper-container", carouselWrapper), isHasBtn);
	});
}
function requestSwiper() {
	return new Promise(res => {
		const swiperScript = $dqs('script[src*="swiper@11/swiper-bundle.min.js"]');
		if (swiperScript) {
			if (typeof Swiper !== "undefined") {
				res();
			} else {
				swiperScript.addEventListener("load", () => {
					res();
				});
			}
		} else {
			const link = document.createElement("link");
			link.rel = "stylesheet";
			link.href =
				"https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css";
			document.head.appendChild(link);
			const script = document.createElement("script");
			script.setAttribute("defer", true);
			script.src =
				"https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
			document.head.appendChild(script);
			script.onload = () => res();
		}
	});
}

function initSwiper(wrapper, isHasBtn) {
	const autoplay = {
		delay: u.bsCarouselSpeed || 3000,
		pauseOnMouseEnter: true,
	};
	new Swiper(wrapper, {
		loop: true,
		speed: 300,
		spaceBetween: 200,
		slidesPerView: 1,
		autoplay,
	});
	const swiper = wrapper.swiper;
	if (isHasBtn) {
		wrapper.previousElementSibling.onclick = () => {
			swiper.slidePrev();
		};
		wrapper.nextElementSibling.onclick = () => {
			swiper.slideNext();
		};
	}
}
