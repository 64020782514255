import v from "./variable";
import u from "./../user_need/user_need";
import { sgPage } from "../reviews/review-pager";
import { get_suffix } from "./utils";

// 封装xhr请求
function httpRequest(
	partUrl,
	params = {},
	method = "GET",
	success,
	isStatic = false,
	isOss,
	isOtherUrl,
	fail
) {
	// 获取xmlHttpRequest对象
	let xmlHttp = new XMLHttpRequest();
	// API路由
	// let url = baseUrl + 'api/v1/email/' + urlSuffix;
	var baseUrl = `https://${Review.baseUrl}/api/v1/`;
	let url = "";
	if (isStatic || isOtherUrl) {
		url = partUrl;
	} else if (isOss) {
		url = v.uploadImgInfo.host;
	} else {
		url = baseUrl + partUrl;
	}
	let paramsStr = "";
	if (typeof params === "object" && !isOss) {
		paramsStr = Object.keys(params).reduce(
			(pre, item) => pre + "&" + item + "=" + params[item],
			""
		);
	}

	// post请求方式
	if (method == "POST") {
		// 添加http头，发送信息至服务器时的内容编码类型
		// xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		// 发送数据，请求体数据
		// xmlHttp.send(`product_id=${params.product_id}&shop_id=${params.shop_id}`);
		if (paramsStr && !isOss) {
			paramsStr = paramsStr.slice(1);
		} else {
			paramsStr = params;
		}
	} else if (method == "GET") {
		if (paramsStr) {
			paramsStr = paramsStr.replace("&", "?");
			url += paramsStr;
		}
	}

	xmlHttp.open(method, url, true);

	if (paramsStr && method == "POST") {
		// 发送数据
		xmlHttp.send(paramsStr);
	} else {
		xmlHttp.send();
	}

	xmlHttp.onreadystatechange = () => {
		if (xmlHttp.readyState == 4) {
			let resData = xmlHttp.responseText;
			if (xmlHttp.status == 200 || xmlHttp.status == 203)
				success && success(resData);
			else
				fail && fail({ responseText: resData, statusText: xmlHttp.statusText });
		}
	};
}
// promise封装请求
function promiseRequest(
	partUrl,
	params,
	method,
	isStatic = false,
	isOss = false,
	isOtherUrl = false
) {
	return new Promise((res, rej) => {
		httpRequest(partUrl, params, method, res, isStatic, isOss, isOtherUrl, rej);
	})
		.then(data => {
			if (isOss) {
				data = { name: params.get("name"), key: params.get("key") };
			} else if (!isStatic) {
				data = JSON.parse(data);
			}
			if (!isStatic && !isOss) {
				const code = data.code;
				if (code === -1) {
					throw data;
				} else if (code === -2) {
					throw data;
				}
				data = data.data;
			}
			return [null, data];
		})
		.catch(err => {
			let arr = [{ error: err.message, params, code: err.code }];
			if (isOss) {
				arr.push({ name: params.get("name") });
			}
			return arr;
		});
}

//Mark 错误信息反馈
function sendErrorMsg(type, content) {
	requests.sendErrorMsg(type, content);
}
//Mark 请求相关
const requests = {
	// 获取用户后台设置
	getUserSetting() {
		let url = "users/get_reviews_settings";

		let params = {
			shop_id: Review.shop_id,
		};
		return promiseRequest(url, params);
	},
	// 获取店铺安装状态
	getInstallStatus() {
		let url = "reviews/get_shop_install_status";
		let params = {
			shop_id: Review.shop_id,
		};
		return promiseRequest(url, params);
	},
	getSvg(name) {
		const url =
			"https://cdn.vstar.app/static/images/svg/customer/" + name + ".svg?55555";
		return promiseRequest(url, {}, "GET", true);
	},
	getIcons() {
		const url = "https://cdn.vstar.app/static/customer/icons.json";
		return promiseRequest(url, {}, "GET", true);
	},
	// 获取用户需求信息
	getUserNeed() {
		let url = "reviews/get_custom_info";
		let params = { shop_id: Review.shop_id };
		return promiseRequest(url, params);
	},
	// 获取店铺自定义多语言词汇
	getLang() {
		const url = "reviews/get_customer_self_define_lang";
		const params = {
			shop_id: Review.shop_id,
			lang: Review.lang,
		};
		return promiseRequest(url, params);
	},
	// 获取产品的评分数据
	getProductRating(handle) {
		let url = "reviews/get_product_rating";
		let params = {
			shop_id: Review.shop_id,
		};
		let productId = v.productId;
		if (productId) params.product_id = productId;
		else if (handle) params.product_handle = handle;
		return promiseRequest(url, params);
	},
	// 获取收藏页产品的评分数据
	getCollRating(handle, id) {
		let url = "reviews/get_products_rating";
		let params = {
			shop_id: Review.shop_id,
		};
		if (handle) params.product_handle = handle;
		else params.product_id = id;
		return promiseRequest(url, params);
	},
	// 获取产品的评论列表数据
	getProRevList(
		sortType,
		ratingFilter,
		page,
		limit,
		isBuyersShow,
		hasResource,
		reviewId
	) {
		// let isAllUrl = true;
		// let url = location.origin + "/apps/trustoo/api/v1/reviews/get_reviews";
		// if (location.origin.includes("http://localhost") || u.useOriginalRequest) {
		// 	url = "reviews/get_product_reviews";
		// 	isAllUrl = false;
		// }
		let url = "reviews/get_product_reviews";
		if (!page && page !== 0) {
			page = sgPage();
		}
		if (!limit) {
			limit = v.layoutReviewCount;
		}

		let params = {
			shop_id: Review.shop_id,
			limit,
		};

		if (page) {
			params.page = page;
		}

		if (v.isWdoRevOpen) {
			params.scene = 2;
			if (u.isPopupProdReviews && v.productId) {
				params.product_id = v.productId;
			} else {
				params.is_show_all = 1;
			}
		} else if (v.isMixReview) {
			params.scene = 1;
			params.is_show_all = 1;
		} else {
			params.product_id = v.productId;
		}

		if (ratingFilter !== 0) {
			params.rating_filter = ratingFilter;
		}

		if (isBuyersShow) {
			params.is_buyers_show = isBuyersShow;
		}

		if (params.is_show_all === 1 && !isBuyersShow) {
			v.allRevLoadLock = true;
		}

		if (hasResource) {
			params.has_resource = 1;
		}

		if (reviewId) {
			params.review_id = reviewId;
		}

		params.sort_by = sortType;

		if (
			v.userSetting.is_translate_review === 1 ||
			v.userSetting.auto_switch_language === 1
		) {
			params.lang = Review.lang;
		}
		// if (v.isReviewsUpdate) {
		params.t = v.revReqParam;
		// }
		return promiseRequest(url, params);
		/* 		undefined,
		undefined,
		undefined,
		isAllUrl */
	},
	// 获取产品信息
	getProductInfo(product_title) {
		let url = "products/get_shopify_product_info";
		let params = {
			shop_id: Review.shop_id,
		};
		if (product_title) params.product_title = product_title;
		else params.product_id = v.productId;
		return promiseRequest(url, params);
	},
	// 收集数据
	recordInfo(params) {
		let url = location.origin + "/apps/trustoo/api/v1/reviews/collect";
		return promiseRequest(url, params, "GET", true);
	},
	// 店铺是否只有一个产品
	getOnlyOneProduct() {
		let url = "products/get_product_only_one";
		let params = {
			shop_id: Review.shop_id,
		};
		return promiseRequest(url, params);
	},
	// 获取店铺写评价设置
	getWriteRevSetting() {
		let url = "reviews/get_write_review_setting";
		return promiseRequest(url, { shop_id: Review.shop_id });
	},
	// 获取上传图片的oss的token
	getPolicyToken() {
		let url = "review/get_policy_token";
		return promiseRequest(url, { shop_id: Review.shop_id });
	},
	// 上传评论图片
	uploadImg(img) {
		const uploadImgInfo = v.uploadImgInfo;
		let key = get_suffix(img.name);
		let formData = new FormData();
		formData.append("name", img.name);
		formData.append("key", uploadImgInfo["dir"] + key);
		formData.append("policy", uploadImgInfo["policy"]);
		formData.append("OSSAccessKeyId", uploadImgInfo["accessid"]);
		formData.append("success_action_status", "200");
		formData.append("callback", uploadImgInfo["callback"]);
		formData.append("signature", uploadImgInfo["signature"]);
		formData.append("file", img);

		if (v.shop_id === 72898347282) {
			formData = formData.fd;
		}
		return promiseRequest("", formData, "POST", false, true);
	},
	// 获取上传视频地址
	getUploadVideoUrl() {
		const url = "reviews/get_upload_url";
		return promiseRequest(url, { shop_id: Review.shop_id });
	},
	// 上传视频
	uploadVideo(url, file) {
		let formData = new FormData();
		formData.append("file", file);
		return promiseRequest(url, formData, "POST", true);
	},
	// 上传店铺产品的新评论
	uploadReview(params, uploadType, mediaUrl) {
		const { shop_id } = v;
		const rating = v.ratingVal;
		let isAllUrl = true;
		let url =
			location.origin + "/apps/trustoo/api/v1/reviews/add_review_via_shopify";
		if (location.origin.includes("http://localhost") && u.useOriginalRequest) {
			isAllUrl = false;
			url = "reviews/add";
		}
		// const isRestrict = v.writeRestriction !== 1;
		// const url = isRestrict
		// 	? location.origin + "/apps/trustoo/api/v1/reviews/add_review_via_shopify"
		// 	: "reviews/add";
		params.resources = mediaUrl.map(({ url, resource_type }) => ({
			url,
			resource_type,
		}));

		Object.assign(params, {
			product_id: v.productId,
			shop_id,
			rating,
		});
		return promiseRequest(
			url,
			JSON.stringify(params),
			"POST",
			false,
			false,
			isAllUrl
		);
	},
	// 获取ins动态列表
	getInsList() {
		const url = "reviews/get_instagram_fee_list";
		const { shop_id, insPageSize, insCurPage } = v;
		const params = { shop_id, limit: insPageSize, cur_page: insCurPage };
		return promiseRequest(url, params);
	},
	// 获取店铺ins设置
	getInsSetting() {
		const url = "reviews/get_instagram_setting";
		const { shop_id } = v;
		return promiseRequest(url, { shop_id });
	},
	// 赞评论
	likeOrDislikeReview(likeType, status, review_id) {
		let url = "";
		if (likeType === 1) {
			url = "reviews/like_review";
		} else if (likeType === 2) {
			url = "reviews/dislike_review";
		}
		const { shop_id } = v;
		return promiseRequest(
			url,
			JSON.stringify({ shop_id, review_id, type: status }),
			"POST"
		);
	},
	getBuyerShowsSettings() {
		const url = "reviews/get_buyers_show_settings";
		const { shop_id } = v;
		return promiseRequest(url, { shop_id });
	},
	// 发送错误信息
	sendErrorMsg(type, content) {
		let url = "reviews/warning_feedback";
		// url = 'https://mock.apifox.cn/m1/2610682-0-default/api/v1/reviews/warning_feedback'
		let params = {
			shop_id: Review.shop_id,
			theme_id: v.themeId,
			type,
			content,
		};
		return promiseRequest(url, JSON.stringify(params), "POST");
	},
	//
};

// QA相关的请求
const qaRequests = {
	// 获取QA设置
	getSetting() {
		const url = "reviews/get_qa_settings";
		const params = {
			shop_id: Review.shop_id,
		};
		return promiseRequest(url, params);
	},
	getList(params) {
		const url = "reviews/get_qa_list";
		Object.assign(params, {
			shop_id: Review.shop_id,
			product_id: v.productId,
			page: sgPage(),
			lang: Review.lang,
		});
		return promiseRequest(url, params);
	},
	addQA(params) {
		const url = "reviews/add_qa";
		params.shop_id = Review.shop_id;
		params.product_id = v.productId;
		return promiseRequest(url, JSON.stringify(params), "POST");
	},
	likeOrDislikeQA(likeType, status, qa_id) {
		let url = "";
		if (likeType === 1) {
			url = "reviews/like_qa";
		} else if (likeType === 2) {
			url = "reviews/dislike_qa";
		}
		const { shop_id } = v;
		return promiseRequest(
			url,
			JSON.stringify({ shop_id, qa_id, type: status }),
			"POST"
		);
	},
};
v.getRevList = requests.getProRevList;

export { requests, sendErrorMsg, qaRequests };
// module.exports = network
