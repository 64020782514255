import { setQAList } from "../QA";
import svg from "../common/svg";
import v from "../common/variable";
import { setReviewBody } from "./reviews-body";

// 分页器
function Pager(
	dom,
	position = "beforeend",
	pagerCount = 5,
	pageCount,
	callbackkk,
	type = ""
) {
	this.pageCount = pageCount;
	this.callback = () => {
		if (v.showType === "QA") {
			setQAList();
		} else {
			setReviewBody();
		}
	};
	const callback = this.callback;
	this.activeIndex = 0;
	this.pagerCount = pagerCount;
	this.middleIndex = parseInt(pagerCount / 2);

	const { userSetting, isWdoRevOpen } = v;
	const reviewsDom = isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const prePageIcon = svg.prePage(userSetting.font_color);
	const nextPageIcon = svg.nextPage(userSetting.font_color);
	const isRtl = dom.parentNode.classList.contains("trustoo-rtl");
	const preBtn = 1;
	const nextBtn = 2;

	var pagerStr = `<ul class="page-control" type="${type}">
            <li style="display:none" class="page-control-button pre-page">
              ${isRtl ? nextPageIcon : prePageIcon}
            </li>`;
	for (var i = 1; i <= pagerCount; i++) {
		pagerStr += `<li class="page-control-item ${
			i === 1 ? "active" : ""
		} " data-index="${i - 1}" data-number="${i}" >${i}</li>`;
	}

	pagerStr += `<li class="page-control-button next-page">
              ${isRtl ? prePageIcon : nextPageIcon}
            </li>
            </ul>`;
	dom.insertAdjacentHTML(position, pagerStr);
	this.el = reviewsDom.querySelector(`.page-control`);

	this.pageNumList = this.el.querySelectorAll(`.page-control-item`);
	if (pageCount < 5 && pageCount !== 1) {
		for (var i = pageCount; i < 5; i++) {
			this.pageNumList[i].style.display = "none";
		}
	}
	setTimeout(() => {
		if (pageCount === 1) {
			this.togglePageButton(2);
		}
	}, 0);
	this.el.onclick = e => {
		if (e.target.classList.contains("page-control-item")) {
			this.activeIndex = e.target.dataset.index;
			let page = parseInt(e.target.textContent);
			this.pageClick(callback, page);
		}
	};
	// this.pageNumList.forEach(item => {
	// 	item.onclick = () => {
	// 		this.activeIndex = item.dataset.index;
	// 		let page = parseInt(item.textContent);
	// 		this.pageClick(callback, page);
	// 	};
	// });
	//按钮点击事件
	this.pageClick = function (callback, page) {
		let prePage = this.el.querySelector(
			`.page-control-item.active`
		).textContent;
		const curPageCount = this.pageCount;
		sgPage(3, page);
		if (callback) {
			callback();
		} else {
			this.activeIndex = 0;
		}

		if (prePage == 1 && page != 1) {
			this.togglePageButton(preBtn, "block");
		} else if (prePage != 1 && page == 1) {
			this.togglePageButton(preBtn, "none");
		}
		if (prePage != curPageCount && page == curPageCount) {
			this.togglePageButton(nextBtn, "none");
		} else if (prePage == curPageCount && page != curPageCount) {
			this.togglePageButton(nextBtn, "block");
		}
		this.setPageNum();
	};

	var prePageButton = this.el.querySelector(".pre-page");
	var nextPageButton = this.el.querySelector(".next-page");
	this.prePageButton = prePageButton;
	this.nextPageButton = nextPageButton;
	//按钮状态切换
	this.togglePageButton = function (type, display) {
		if (type == 1) {
			prePageButton.style.display = display;
			// if (prePageButton.style.display === "none") {
			// 	prePageButton.style.display = "flex";
			// } else {
			// 	prePageButton.style.display = "none";
			// }
		} else if (type == 2) {
			nextPageButton.style.display = display;
			// if (nextPageButton.style.display === "none") {
			// 	nextPageButton.style.display = "flex";
			// } else {
			// 	nextPageButton.style.display = "none";
			// }
		}
	};

	//Mark 判断页码状态
	this.setPageNum = function () {
		let page = sgPage();
		const curPageCount = this.pageCount;
		const pagerCount = this.pagerCount;
		if (
			(this.activeIndex > this.middleIndex &&
				this.pageNumList[pagerCount - 1].innerHTML <= curPageCount) ||
			(this.activeIndex < this.middleIndex && this.pageNumList[0].innerHTML > 1)
		) {
			if (
				page + this.middleIndex > curPageCount ||
				page - this.middleIndex <= 0
			) {
				this.el
					.querySelector(".page-control-item.active")
					.classList.remove("active");
			} else if (this.activeIndex != this.middleIndex) {
				this.el
					.querySelector(".page-control-item.active")
					.classList.remove("active");
				this.activeIndex = this.middleIndex;
				this.pageNumList[this.middleIndex].classList.add("active");
			}
			this.changePageNum();
		} else {
			this.el
				.querySelector(".page-control-item.active")
				.classList.remove("active");
			this.pageNumList[this.activeIndex].classList.add("active");
		}
	};

	//下一页
	this.getNextPageReviews = function () {
		sgPage(1);
		const curPageCount = this.pageCount;
		let page = sgPage();
		this.activeIndex++;

		if (page == 2) {
			this.togglePageButton(preBtn, "block");
		}
		if (page == curPageCount) {
			this.togglePageButton(nextBtn, "none");
		}
		if (callback) {
			callback();
		}
		//getReviewBody()

		this.setPageNum(2);
	};
	//上一页
	this.getPrePageReviews = function () {
		const curPageCount = this.pageCount;
		this.activeIndex--;
		sgPage(2);
		let page = sgPage();
		if (page == 1) {
			this.togglePageButton(preBtn, "none");
		}
		if (page == curPageCount - 1) {
			this.togglePageButton(nextBtn, "block");
		}

		if (callback) {
			callback();
		}
		// getReviewBody()

		this.setPageNum(1);
	};

	nextPageButton.onclick = () => this.getNextPageReviews();
	prePageButton.onclick = () => this.getPrePageReviews();

	//Mark 换页码
	this.changePageNum = function () {
		const curPageCount = this.pageCount;
		const pagerCount = this.pagerCount;
		let page = sgPage();
		var noPageFlag = false;
		var startNum = page - this.middleIndex;
		var endNum = page + this.middleIndex;
		if (page + this.middleIndex > curPageCount) {
			noPageFlag = true;
			startNum = curPageCount - 2 * this.middleIndex;
			if (pagerCount % 2 == 0) {
				startNum++;
			}
			endNum = curPageCount;
		} else {
			if (pagerCount % 2 == 0) {
				endNum--;
			}
		}

		if (startNum <= 0) {
			noPageFlag = true;
			startNum = 1;
			endNum = startNum + 2 * this.middleIndex;
		}

		var index = 0;
		for (var i = startNum; i <= endNum; i++) {
			if (i === page && noPageFlag) {
				this.pageNumList[index].classList.add("active");
				this.activeIndex = index;
			}
			this.pageNumList[index].innerHTML = i;
			index++;
		}
	};
	this.clearPageItems = () => {
		const pageItems = v.pager.el.querySelectorAll(".page-control-item");
		pageItems.forEach(item => item.remove());
	};
}
/**
 * 管理页面导航并更新页面信息。
 *
 * @param {number} type - 操作类型:
 *   - 1: 前进到下一页
 *   - 2: 返回到上一页
 *   - 3: 设置页数
 * @param {number} [num] - 当 type 为 3 时，指定要跳转的页面号
 * @returns {number} 操作后的当前页码
 *
 * @description
 * 函数根据视图类型（reviews 或 qa）处理页面导航，并在 DOM 元素上设置适当的属性。
 * 它还会更新全局页面信息对象中的当前页面值。
 *
 * @example
 * // 页数+1
 * const nextPage = sgPage(1);
 *
 * @example
 * // 页数-1
 * const prevPage = sgPage(2);
 *
 * @example
 * // 设置页数为第5页
 * const specificPage = sgPage(3, 5);
 */
function sgPage(type, num) {
	let reviewsDom = v.isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	const key = v.showType === "reviews" ? "reviews-page-no" : "qa-page-no";
	// let page = parseInt(reviewsDom.getAttribute(key));
	const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;
	const curPageInfo = pageInfo[v.showType];
	let page = curPageInfo.curPage;
	if (arguments.length === 1) {
		if (type === 1) {
			page++;
		} else if (type === 2) {
			page--;
		}
		curPageInfo.curPage = page;
		reviewsDom.setAttribute(key, page);
	} else if (type === 3 && arguments.length === 2) {
		reviewsDom.setAttribute(key, num);
		curPageInfo.curPage = num;
	}
	return page;
}
function clearPageItems(paginationContainer) {
	const pageItems = paginationContainer.querySelectorAll(".page-control-item");
	pageItems.forEach(item => item.remove());
}
function updateNumberPagination() {
	const pageNumberPagination = 1;
	if (
		v.userSetting.review_pagination_type !== pageNumberPagination ||
		!v.pager
	) {
		return;
	}
	// 更新当前标签页和分页信息
	const paginationContainer = v.pager.el;
	// 获取当前标签页的分页信息
	const currentPageInfo = v.pageInfo[v.showType];
	if (!currentPageInfo) return;
	const { total, curPage } = currentPageInfo;
	v.pager.pageCount = total;
	// 如果总页数小于等于1，隐藏分页器
	if (total <= 1) {
		paginationContainer.style.display = "none";
		return;
	}
	// 显示分页器
	paginationContainer.style.display = "flex";

	// 更新分页器的type属性
	paginationContainer.setAttribute("type", v.showType);

	// 清除现有的页码项（保留前/后按钮）
	clearPageItems(paginationContainer);

	// 根据pageNumber或total渲染分页
	if (currentPageInfo.pageNumbers && currentPageInfo.pageNumbers.length > 0) {
		renderFromPageNumbers(paginationContainer, currentPageInfo.pageNumbers);
	} else {
		renderFromTotal(paginationContainer, total);
	}
	// 获取当前标签页保存的活动页码

	const activeIndex = currentPageInfo.activeIndex || 0;
	const activePage = paginationContainer.querySelector(
		`.page-control-item[data-index="${activeIndex}"]`
	);
	if (activePage) {
		activePage.classList.add("active");

		// 更新当前标签页的activeIndex
		currentPageInfo.activeIndex = activeIndex;
	}
	v.pager.activeIndex = activeIndex;

	v.pager.pageNumList =
		paginationContainer.querySelectorAll(".page-control-item");
	v.pager.pagerCount = v.pager.pageNumList.length;
	v.pager.middleIndex = parseInt(v.pager.pagerCount / 2);
	v.pager.prePageButton.style.display = curPage === 1 ? "none" : "flex";
	v.pager.nextPageButton.style.display = curPage === total ? "none" : "flex";
}

/**
 * 根据提供的页码数组渲染分页项
 * @param {Array} pageNumbers - 要显示的页码数组
 */
function renderFromPageNumbers(paginationContainer, pageNumbers) {
	const nextPageButton = paginationContainer.querySelector(".next-page");

	pageNumbers.forEach((pageNum, index) => {
		const pageItem = document.createElement("li");
		pageItem.className = "page-control-item";
		pageItem.dataset.index = index;
		pageItem.dataset.number = pageNum;
		pageItem.textContent = pageNum;

		// 在下一页按钮之前插入
		paginationContainer.insertBefore(pageItem, nextPageButton);
	});
}

/**
 * 根据总页数渲染分页
 * @param {number} total - 总页数
 */
function renderFromTotal(paginationContainer, total) {
	// 计算页码（最多显示5页）
	const maxPages = Math.min(total, 5);
	const nextPageButton = paginationContainer.querySelector(".next-page");

	for (let i = 1; i <= maxPages; i++) {
		const pageItem = document.createElement("li");
		pageItem.className = "page-control-item";
		pageItem.dataset.index = i - 1; // 设置索引
		pageItem.dataset.number = i;
		pageItem.textContent = i;

		// 在下一页按钮之前插入
		paginationContainer.insertBefore(pageItem, nextPageButton);
	}
}
function getPageInfoKey() {
	return v.showType;
	if (v.isWdoRevOpen) {
		if (v.showType === "reviews") {
			return "popupReviews";
		}
		return "popupReviews";
	} else {
		return v.showType;
	}
}
function setPageInfo(data) {
	const type = v.showType;
	const { total_page: total } = data;
	let key = "reviews";
	if (v.isWdoRevOpen) {
		key = "popupReviews";
	} else if (type === "reviews") {
		v.pageInfo.reviews = { total };
	} else if (type === "shopReviews") {
		key = "shopReviews";
		v.pageInfo.reviews = { total };
	} else if (type === "QA") {
		key = "QA";
	}
	v.pageInfo[key] = { total };
}

export { Pager, sgPage, getPageInfoKey, setPageInfo, updateNumberPagination };
