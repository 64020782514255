import v from "../common/variable";
import { getTotalStarString, processRecordedData } from "../page/page-common";
import {
	$dqs,
	getWindowWidth,
	debounce,
	$dqsa,
	gsSession,
	setToast,
} from "../common/utils";
import { arrangeGrid } from "./grid";
import { sgPage } from "./review-pager";
import u from "../user_need/user_need";
import { addCloseWdoEvent } from "./window-review";
import { pageflyProRatingClk } from "../page/product";
import { setReviewHead } from "./reviews-head";
import {
	checkHelpful,
	ellipsisTextDisplay,
	setReviewBody,
} from "./reviews-body";
import { insertQA } from "../QA";
import svg from "../common/svg";
import { closeWindow, inputCheck, showOneInAll } from "./allinone-write-review";
import { closeSlide, inputDebounce } from "./slide-write-review";
import { updatePagination } from "./reviews-footer";
import reviewsNodes from "../common/node";
import showProductSelector from "./product-selector";

// if (module.hot) {
// 	module.hot.accept("./reviews-body", function () {
// 		console.log("reviews-body 更新");
// 	});
// }

//Mark 构造评论区
async function getReviews(insertInfo) {
	const { revIdBase, isWdoRevOpen } = v;
	let reviewsDom, reviewsBody;
	const review = getReviewHtml();

	insertReview(review, insertInfo.tarNode, insertInfo.position);

	if (insertInfo.tarNode && isWdoRevOpen) {
		let wdoRevDom = $dqs(`#${revIdBase}reviews`, insertInfo.tarNode);
		let wdoRevBody = $dqs("#reviews-body", insertInfo.tarNode);
		reviewsDom = wdoRevDom;
		reviewsBody = wdoRevBody;
		Object.assign(v, { wdoRevDom, wdoRevBody });
	} else {
		reviewsDom = $dqs(`#${revIdBase}reviews`);
		reviewsBody = $dqs("#reviews-body");
		Object.assign(v, { reviewsDom, reviewsBody });
	}

	reviewsDom.setAttribute("review_sort_by", v.userSetting.review_sort_by);
	const layout = v.userSetting.layout === 1 ? "list" : "gird";
	reviewsDom.setAttribute("layout", layout);

	if (!isWdoRevOpen) {
		v.reviewsDom && setReviewWidth();
	}

	//Mark 瀑布流配置
	let reviewsWidth = parseInt(window.getComputedStyle(reviewsDom).width);
	if (v.isGrid) {
		v.reviewsWidth = reviewsWidth;
	}

	// //获取总评相关信息
	// getTotalRating()
	sgPage(3, 1);
	const key = isWdoRevOpen ? "trustoo_all_reviews" : "trustoo_reviews";
	gsSession("set", key, []);

	// const curReviews = reviewsNodes[v.reviewsKey];
	// curReviews.reviewsBody = $dqs(".tt-review-list", reviewsBody);
	insertTab(reviewsDom, reviewsBody);
	saveNodes(reviewsDom);
	await setReviewBody();
	setReviewHead();
	v.pageInfo.current = isWdoRevOpen ? "popupReviews" : "reviews";
	updatePagination();
	// setReviewFooter(reviewsDom);

	// 是否隐藏写评论按钮，只剩排序按钮，需要修改移动端评论区头部的水平对齐
	const firstChildSort = $dqs(".head-sort:first-child", reviewsDom);
	if (firstChildSort) {
		firstChildSort.parentNode.setAttribute("only-sort", true);
	}
	afterInsertReviews(reviewsDom);
	// const curReviews = reviewsNodes[v.reviewsKey];
	// curReviews.curBody = curReviews.normalReviews;

	setReviewEvent();

	if (isWdoRevOpen) {
		addCloseWdoEvent();
	} else {
		if (
			Object.keys(v.allRatingData || v.ratingData).length !== 0 ||
			Object.keys(v.ratingData).length !== 0
		) {
			smallWidRev();
		}
	}

	if (v.isFirstPageRender) {
		afterFirstRender();
		v.isFirstPageRender = false;
	}
}

// Mark 获取评论区的html
function getReviewHtml() {
	var reviewsHead = "";
	// var reviewsFooter = "";
	// 评论区tab
	const { revIdBase, isWdoRevOpen } = v;
	const { nextRevDesc } = u;

	let typeClass = "",
		allRevCls = "",
		extraClass = "";

	if (["ar", "he"].includes(Review.lang)) {
		extraClass = " trustoo-rtl";
	}

	if (!v.isWdoRevOpen) {
		extraClass += " pending";
	}

	reviewsHead = `<div id="reviews-head" ${typeClass} >
  </div>`;

	// const tabHtml = insertTab();
	// if (tabHtml) {
	// 	extraClass += " qa";
	// }

	let next_review_desc = "";
	if (nextRevDesc) {
		next_review_desc = `<div id="review-desc" style="margin-top:26px;color:#666666">${nextRevDesc}</div>`;
	}

	// 评论区主体外壳
	var reviewsBody = `<div id="reviews-body"><div class="tt-review-list"></div>
    </div>`;
	// reviewsFooter = `<div id="reviews-footer">
	//   <div class="loader" style="display:none;"></div>
	//  </div>`;

	const isNoWdoRev = isWdoRevOpen ? "" : "not-wdo-rev";
	const isAllReviews = v.isMixReview ? "all-reviews" : "";
	const productId = v.productId ? `data-product-id="${v.productId}"` : "";
	// 评论区添加内容
	const review = `<div id="reviews-wrapper" class="trustoo-reviews-wrapper">
  <div id="${revIdBase}reviews" show-type="reviews" ${isNoWdoRev} ${isAllReviews} ${productId}  class="trustoo-widget ${
		allRevCls + extraClass
	}">
    ${reviewsHead + reviewsBody + next_review_desc}</div>
  </div>`;

	return review;
}
// Mark 添加公共事件
function setCommonEvent() {
	const { isMixReview, isWdoRevOpen } = v;
	const reviewsDom = isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	// 写评价按钮点击事件
	const writeRevBtn = $dqs(
		".head-button.tt-write-reviews,.noreviews-button.tt-write-reviews",
		reviewsDom
	);
	if (writeRevBtn) {
		writeRevBtn.addEventListener("click", () => {
			handleWriteReview(
				v.isProPage && !v.isWdoRevOpen ? "normal" : v.writeTo.type
			);
		});
	}

	// 开启QA,且不是全部评论区和弹窗评论区
	if (v.userSetting.is_qa_active === 1 && !isWdoRevOpen && !isMixReview) {
		const writeQABtn = $dqs(
			".head-button.tt-write-qa,.noreviews-button.tt-write-qa",
			reviewsDom
		);

		writeQABtn.addEventListener("click", function () {
			addHash();
			// document.body.classList.add("trustoo-open");
			// $dqs("#trustoo-mask").style.display = "flex";
			// const write = $dqs("#trustoo-allinone");
			// // 写QA弹窗修改文本
			// $dqs(".head-title", write).textContent =
			// 	v.lang.question_and_answer.ask_a_question;
			// $dqs(".big-input", write).placeholder =
			// 	v.lang.question_and_answer.desc_placeholder;
			// $dqs(".feedback-area>.tt-input-title", write).textContent =
			// 	v.lang.question_and_answer.question;
			// $dqs(".form-submit", write).textContent =
			// 	v.lang.question_and_answer.submit_question;

			// write.style.display = "block";
			showOneInAll("QA");
		});
	}

	//Mark Tab的点击事件
	$dqsa(".tt-tab", reviewsDom).forEach(item => {
		item.onclick = function () {
			const paginationType = v.userSetting.review_pagination_type,
				pageNumberPagination = 1,
				morePagination = 2,
				reviewIndex = "0",
				qaIndex = "1",
				shopReviewIndex = "2";
			const curReviews = reviewsNodes[v.reviewsKey];
			if (v.pager && !isWdoRevOpen) {
				v.pager.el.style.display = "none";
			}
			if (
				paginationType === pageNumberPagination &&
				!isWdoRevOpen &&
				!v.isRevPage
			) {
				savePageInfo();
			} else if (paginationType === morePagination) {
			}

			$dqs(".tt-tab.active", reviewsDom).classList.remove("active");
			item.classList.add("active");
			const index = item.getAttribute("index");

			let key = "reviews";
			v.showType = "reviews";
			// 点击评论tab
			if (index === reviewIndex) {
				curReviews.curBody = curReviews.normalReviews;
				updatePagination();
			} else if (index === qaIndex) {
				const qaNode = $dqs(".tt-qa-list", reviewsDom);
				// 点击QA tab
				// v.showType = "QA";
				// v.reviewsDom.setAttribute("show-type", "QA");
				v.showType = "QA";
				key = "QA";
				if (qaNode) {
					updatePagination();
				} else {
					// QA不存在，进行嵌入
					v.pageInfo.QA = { curPage: 1 };
					insertQA();
				}
			} else if (index === shopReviewIndex) {
				const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;
				v.showType = "shopReviews";
				key = "shopReviews";
				const shopReviews = curReviews.shopReviews;
				if (shopReviews) {
					updatePagination();
					curReviews.curBody = shopReviews;
				} else {
					const insertNode = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;
					if (v.userSetting.store_reviews_count === 0) {
						pageInfo.shopReviews = { total: 0 };
						updatePagination();
						insertNode.insertAdjacentHTML(
							"beforeend",
							`<div class="tt-shop-reviews" style="margin-top: 32px;">
								<div class="vstar-star">${getTotalStarString(0, 20)}</div>
								<div style="font-size: 20px;text-align: center;margin-top: 4px;">${
									v.lang.no_reviews
								}</div></div>`
						);
					} else {
						insertNode.insertAdjacentHTML(
							"beforeend",
							`<div class="tt-shop-reviews"></div>`
						);
						const sessionKey = v.isWdoRevOpen
							? "trustoo_popup_shop_reviews"
							: "trustoo_shop_reviews";
						gsSession("set", sessionKey, []);
						if (v.userSetting.store_reviews_count !== 0) {
							const total =
								(v.popupPageInfo.shopReviews || v.pageInfo.shopReviews)
									?.total || 0;
							pageInfo.shopReviews = { curPage: 1, total };
							setReviewBody().then(() => {
								updatePagination();
							});
						}
					}
					curReviews.shopReviews = $dqs(".tt-shop-reviews", reviewsDom);
					curReviews.curBody = curReviews.shopReviews;
				}
				// v.reviewsDom.setAttribute("show-type", "shopReviews");
			}
			// if (v.userSetting.review_pagination_type === pageNumberPagination) {
			// 	updatePagination();
			// } else {
			// 	updateMoreBtnDisplay();
			// }
			reviewsDom.setAttribute("show-type", key);
		};
	});
}

function savePageInfo() {
	// const previousBtn = wrapper.querySelector(".pre-page");
	// const nextBtn = wrapper.querySelector(".next-page");
	const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;

	const info = pageInfo[v.showType];
	if (info && v.pager) {
		const pagers = v.pager.el.querySelectorAll(".page-control-item");
		info.pageNumbers = Array.from(pagers).map(item => item.textContent);
		// info.previousDisplay = previousBtn.style.display;
		// info.nextDisplay = nextBtn.style.display;
		info.activeIndex = v.pager.activeIndex;
		// console.log("切换后保留分页器数据，数据如下：", info);
	}
}

// Mark 添加事件
function setReviewEvent() {
	const { isWdoRevOpen, hasPyRating } = v;

	const reviewsBody = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;

	if ($dqs("#reviews-head .head-sort,.tt-reviews-sort")) {
		// 评论排序事件
		setRevSort();
	}

	// 评论主体点击事件
	reviewsBody.addEventListener("click", function (e) {
		let target = e.target;
		if (target.tagName === "path") {
			target = target.closest("svg");
		}
		let tarCls = target.classList;
		if (tarCls.contains("resource-item")) {
			target = $dqs("img", target);
		}
		const resourceItem = e.target.closest(".resource-item");
		//## 图片点击事件
		if (resourceItem) {
			const imgEle = $dqs("img", resourceItem);
			let no = parseInt(imgEle.getAttribute("no"));
			const imageIndex = parseInt(imgEle.getAttribute("index"));
			if (v.isGrid && v.isMobile) {
				v.globalStyle.textContent +=
					"#trustoo-review-detail .review-row-one .reviews-date {display: none;}";
			}
			let cardPage;
			if (
				v.userSetting.review_pagination_type === 1 &&
				!v.isWdoRevOpen &&
				(!v.isMixReview || v.isHomePage || v.isCollPage)
			) {
				cardPage = sgPage();
			} else {
				cardPage = parseInt(imgEle.dataset.page);
			}
			// no -= 1;
			// no %= v.userSetting.reviews_per_page_number;
			no = imgEle.dataset.reviewIndex;

			v.revDetailInfo.page = cardPage;
			v.revDetailInfo.no = no;
			v.revDetailInfo.imageIndex = imageIndex;
			let key = "trustoo_reviews";
			if (v.isWdoRevOpen) {
				if (v.showType === "shopReviews") {
					key = "trustoo_popup_shop_reviews";
				} else {
					key = "trustoo_all_reviews";
				}
			} else {
				if (v.showType === "shopReviews") {
					key = "trustoo_shop_reviews";
				}
			}

			// const key = v.isWdoRevOpen ? "trustoo_all_reviews" : "trustoo_reviews";
			const reviewsList = gsSession("get", key);
			const data = reviewsList[cardPage - 1][no];

			TTRevDetail.showTTReviewDetail(v, "", data, key);
		} else if (tarCls.contains("display-text")) {
			// 展开评论点击
			ellipsisTextDisplay(target);
		} else if (
			// 赞踩点击
			tarCls.contains("tt-helpful") ||
			tarCls.contains("tt-unhelpful")
		) {
			checkHelpful(target, tarCls);
		}
	});

	// 实现pagefly产品页星星评价点击跳转评论区
	if (hasPyRating) pageflyProRatingClk();
	// 评论区页的滚动加载
	if (!v.iObserver) {
		v.iObserver = new IntersectionObserver(entries => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					if (!v.allRevLoadLock) {
						if (v.isRevPage || v.isWdoRevOpen) {
							const revNode = entry.target.parentNode;
							const pageInfo = v.isWdoRevOpen ? v.popupPageInfo : v.pageInfo;
							const pageCount = pageInfo[v.showType].total;
							const page = sgPage();
							if (page < pageCount) {
								v.allRevLoadLock = true;
								$dqs(".loader", revNode).style.display = "block";
								sgPage(1);
								setReviewBody(false);
							}
						}
					}
				} else {
					v.allRevLoadLock = false;
				}
			}
		}, {});
	}

	let observerNode = null;
	if (!v.isWdoRevOpen) {
		if (v.isRevPage) {
			observerNode = $dqs("#reviews-footer", v.reviewsDom);
		}
	} else {
		observerNode = $dqs("#reviews-footer", v.wdoRevDom);
	}
	observerNode && v.iObserver.observe(observerNode);
}

// ## 构造Tab栏
function insertTab(reviewsDom, reviewsBody) {
	let reviewsTab = "",
		sortHtml = "";
	const isShowQA =
		v.userSetting.is_qa_active === 1 && v.isProPage && !v.isWdoRevOpen;
	const isShowShop = v.userSetting.is_active_store_review === 1;
	let reviewsNum = 0;
	if (!v.isNullRev) {
		if (v.isWdoRevOpen || v.isMixReview) {
			reviewsNum = v.allRatingData.total_reviews;
		} else {
			reviewsNum = v.ratingData.total_reviews;
		}
	}
	if (
		reviewsNum ||
		v.userSetting.store_reviews_count ||
		v.ratingData.total_qa_count
	) {
		v.canInsertPagination = true;
	}
	// QA开启，并不是弹窗评论区
	if (isShowQA || isShowShop) {
		// const reviews

		reviewsTab = `<div class="tt-tabs-list">
			<span class="tt-tab active" index="0"
				>${v.lang.reviews.charAt(0).toUpperCase() + v.lang.reviews.slice(1)}
				(<span class="tt-number" data-product-reviews>${reviewsNum}</span>)</span
			>
			${
				isShowShop
					? `<span class="tt-tab" index="2"
				>${v.lang.store_review.tab_name}(<span class="tt-number" data-shop-reviews>${v.userSetting.store_reviews_count}</span>)</span
			>`
					: ""
			}
			${
				isShowQA
					? `<span class="tt-tab" index="1"
				>${v.lang.question_and_answer.title}(<span class="tt-number">${v.ratingData.total_qa_count}</span>)</span
			>`
					: ""
			}
		</div>`;
		if (isShowShop) {
			const sortInfo = sortPopupHtml();
			sortHtml = `<div class="tt-reviews-sort">
					<div class="tt-sort-content" style="text-align:right"><span style="margin-right:8px;font-size:14px">${
						sortInfo.text
					}</span>${svg.sort(v.userSetting.font_color)}</div>
					${sortInfo.html}
				</div>`;
		}
	}

	if (reviewsTab) {
		reviewsDom.dataset.tabs = "1";
		reviewsBody.insertAdjacentHTML("beforebegin", reviewsTab + sortHtml);
	}
	if (isShowQA) {
		reviewsDom.classList.add("qa");
	}

	// return reviewsTab + sortHtml;
}

//Mark 构造无评论区
function getEmptyReview(node) {
	const { lang } = v;
	v.isNullRev = true;
	v.firstPageShow = true;

	let extraClass = "";

	if (["ar", "he"].includes(Review.lang)) {
		extraClass = " trustoo-rtl";
	}

	let writeReview = "",
		writeQA = "";

	if (v.userSetting.is_show_review_button !== 2 || v.isMixReview) {
		let btnText = lang.write_reviews;
		if ((v.isMixReview || v.isWdoRevOpen) && v.writeTo.type === "shop") {
			btnText = lang.store_review.write_btn_text;
		}
		writeReview = `<button class="noreviews-button tt-write-reviews" type="button" ${
			v.isMixReview ? `style="display:none"` : ""
		}>
  ${btnText} </button>`;
	}
	if (v.userSetting.is_qa_active === 1 && !v.isWdoRevOpen && !v.isMixReview) {
		writeQA = `<button class="noreviews-button tt-write-qa" type="button">
		${lang.question_and_answer.ask_a_question}
		</button>`;
	}

	const starTag = getTotalStarString(0);
	var review = `  <div id="noreviews-wrapper">  <div id="vstar-noreviews" show-type="reviews" class="trustoo-widget ${extraClass}">
			<div id="tt-first-row">
				<div class="noreviews-title">${lang.customer_reviews}</div> 
				<div id="tt-buttons">${writeReview}${writeQA}</div>
			</div>
      <div class="noreviews-center">
          <div class="nostar-wrapper">
              <div class="noreviews-nostar vstar-star">
                  ${starTag}
              </div>
          </div>
          <p class="noreviews-text">${lang.no_reviews}</p>
      </div><div id="reviews-body"></div>
    </div>
      </div> </div>`;

	insertReview(review, node);

	let reviewsDom = null;
	const bodyKey = v.isWdoRevOpen ? "wdoRevBody" : "reviewsBody";
	const reviewsKey = v.isWdoRevOpen ? "wdoRevDom" : "reviewsDom";
	if (v.isWdoRevOpen) {
		addCloseWdoEvent();
		reviewsDom = $dqs("#vstar-window-review #vstar-noreviews");
	} else {
		reviewsDom = $dqs("#vstar-noreviews");
	}

	const reviewsBody = $dqs("#reviews-body", reviewsDom);
	const center = $dqs(".noreviews-center", reviewsDom);
	v[reviewsKey] = reviewsDom;
	v[bodyKey] = reviewsBody;
	reviewsDom.setAttribute("review_sort_by", v.userSetting.review_sort_by);
	insertTab(reviewsDom, center);
	saveNodes(reviewsDom);
	reviewsNodes[v.reviewsKey].writeBtn = $dqs(".tt-write-reviews", reviewsDom);
	// if (v.canInsertPagination) {
	// 	reviewsDom.insertAdjacentHTML(
	// 		"beforeend",
	// 		`<div id="reviews-footer"></div>`
	// 	);
	// }
	updatePagination();
	// setReviewFooter(reviewsDom);
	afterInsertReviews(reviewsDom);
	setReviewEvent();
	// insertQA();
	setReviewWidth();
}

//## 嵌入评论区
function insertReview(review, node, position = "beforebegin") {
	let isTabReview = false;
	const { isWdoRevOpen, isWdoRevExist } = v;
	let revTar;
	const themeWrapper = Array.from($dqsa("#seal-review-widget")).find(
		it => it.parentNode.offsetParent
	);

	const pageflyWrapper = $dqs(".pagefly-review-widget-sealapp");
	const customWrapper = $dqs(".custom-vstar-review-widget");
	if (isWdoRevOpen && !isWdoRevExist) {
		v.isWdoRevExist = true;
		revTar = $dqs("#vstar-window-review");
		position = "afterbegin";
	} else if (pageflyWrapper) {
		revTar = pageflyWrapper;
		position = "beforeEnd";
	} else if (customWrapper) {
		revTar = customWrapper;
		if (customWrapper.parentNode.offsetParent === null) {
			isTabReview = true;
		}
		position = "beforeEnd";
	} else if (themeWrapper) {
		themeWrapper.parentNode.parentNode.style.padding = 0;
		revTar = themeWrapper;
		position = "beforeEnd";
	} else {
		let isSpecialTarget = null;
		let info = u.get_move_review_info;
		if (Object.keys(info).length) {
			if (v.shop_id === 61244833988 && v.themeId === 130299592900 && v.isMobile)
				info.selector += ">.sf__accordion-content";
			isSpecialTarget = $dqs(info.selector);
			position = info.position;
			isTabReview = info.is_tab_review === 1 ? true : false;
			// }
		} else if (node) {
			isSpecialTarget = node;
		}

		let footer = $dqs("#shopify-section-footer");
		let main = $dqs("main");
		let foot = $dqs("footer");
		let mainTemplate = $dqs(
			'.shopify-section[id$="__main"],#shopify-section-product-template'
		);
		const swiperWrapper = $dqs("#tt-carousels-wrapper");
		if (isSpecialTarget) {
			revTar = isSpecialTarget;
		} else if (swiperWrapper) {
			position = "afterend";
			revTar = swiperWrapper;
		} else if (mainTemplate) {
			position = "afterend";
			revTar = mainTemplate;
		} else if (main) {
			if (main.contains(foot)) {
				revTar = foot;
			} else {
				position = "afterend";
				revTar = main;
			}
		} else if (footer) {
			revTar = footer;
		} else if (foot) {
			revTar = foot;
		} else {
			// requests.sendErrorMsg(5, "主题产品页评论区未找到嵌入位置");
		}
	}
	if (revTar) {
		revTar.insertAdjacentHTML(position, review);

		// reviewWidgetModify()
		!isWdoRevOpen && (v.revTar = revTar);
		v.revTarWid = parseInt(window.getComputedStyle(revTar).width);
		isTabReview && !v.isNullRev && tabReviewObserve();
	}
}

// ## 嵌入评论区后的处理
/**
 * 管理页面导航并更新页面信息。
 *
 * @param {HTMLDivElement} reviewsDom - 评论区的节点
 * @param {HTMLDivElement} reviewsBody - 评论区主体的节点
 */
function afterInsertReviews(reviewsDom) {
	setCommonEvent();
	if (v.isWdoRevOpen || v.isRevPage) {
		reviewsDom.insertAdjacentHTML(
			"beforeend",
			`<div id="reviews-footer"><div class="loader" style="display:none;"></div></div>`
		);
	}
}
/**
 * 管理页面导航并更新页面信息。
 *
 * @param {HTMLDivElement} reviewsDom - 评论区的node
 */
function saveNodes(reviewsDom) {
	const curReviews = reviewsNodes[v.reviewsKey];
	curReviews.normalReviews = $dqs(".tt-review-list", reviewsDom);
	curReviews.curBody = curReviews.normalReviews;
	curReviews.reviewsTab = $dqs(".tt-number[data-product-reviews]", reviewsDom);
	curReviews.shopTab = $dqs(".tt-number[data-shop-reviews]", reviewsDom);
}

// ## 写评论按钮点击事件
function handleWriteReview(writeReviewType) {
	const write = reviewsNodes.write.container;
	write.dataset.reviewType = writeReviewType;

	if (typeof tt_customer !== "undefined") {
		const name = $dqs('[input-type="author"],.slide-name', write);
		const email = $dqs('[input-type="author_email"],.slide-email', write);
		if (v.writeRestriction === 3 && tt_customer.orderCount <= 0) {
			setToast("Please purchase the product first");
			return;
		}
		if (name && email) {
			name.value = tt_customer.name;
			email.value = tt_customer.email;
			if (v.writeForm === 1) {
				inputCheck();
			} else if (v.writeForm === 2) {
				inputDebounce();
			}
		}
	} else {
		if (v.writeRestriction !== 1) {
			location.href = location.origin + "/account/login";
			return;
		}
	}

	document.body.classList.add("trustoo-open");

	addHash();

	reviewsNodes.mask.style.display = "flex";
	if (writeReviewType === "product") {
		showProductSelector();
		// $dqs("#tt-product-selector").style.display = "block";
	} else {
		if (v.writeForm === 1) {
			// 写评论弹窗修改文本
			// $dqs(".head-title", write).textContent =
			// 	v.lang.all_in_one_from.write_reviews;
			// $dqs(".feedback-area>.tt-input-title", write).textContent =
			// 	v.lang.all_in_one_from.review;
			// $dqs(".form-submit", write).textContent = v.lang.submit;
			showOneInAll("reviews");
		} else if (v.writeForm === 2) {
			write.parentNode.style.display = "block";
		}
		write.style.display = "block";
	}

	write.setAttribute("write-review", true);
}

function addHash() {
	if (v.isMobile) {
		let currentHash = window.location.hash;
		// 检查哈希部分是否已经包含 'tt-write-reviews-open'
		if (!currentHash.includes("tt-write-reviews-open")) {
			if (currentHash) {
				window.location.hash = currentHash + "&tt-write-reviews-open";
			} else {
				window.location.hash = "tt-write-reviews-open";
			}
		}
		window.addEventListener("hashchange", handleHashchange);
	}
}

function handleHashchange() {
	if (window.location.hash.indexOf("tt-write-reviews-open") === -1) {
		if (v.writeForm === 1) {
			closeWindow();
		} else if (v.writeForm === 2) {
			closeSlide();
		}
		window.removeEventListener("hashchange", handleHashchange);
	}
}

// Mark 大窗口PC端小宽度评论区适配
function smallWidRev() {
	const reviewsDom = v.reviewsDom;
	// const sort = $dqs(".head-sort", reviewsDom);
	const headCenter = $dqs(".head-center", reviewsDom);
	const headLeft = $dqs(".head-left", reviewsDom);
	const revHead = $dqs("#reviews-head", reviewsDom);
	// reviewsDom.padding = 0;
	v.revTarWid = parseInt(getComputedStyle(v.revTar).width);
	if (v.revTarWid < 678 && !v.isMobile && getWindowWidth() > 768) {
		v.globalStyle.textContent += `#vstar-reviews .list-review{padding:30px 10px !important}
    #vstar-window-review .list-review{padding:30px 40px !important}`;
		headLeft.style.marginLeft = "10px";
		reviewsDom.style.padding = reviewsDom.parentNode.style.padding = 0;
		// 小宽度评论时，按钮的排列优化
		$dqs(".tt-head-right", reviewsDom).style.cssText = "padding: 30px 0 0;";
		if (v.userSetting.is_show_album === 1) {
			const album = $dqs(".tt-reviews-album", reviewsDom);
			if (album) {
				album.style.padding = 0;
			}
		}
	} else {
		reviewsDom.style.cssText = "";
		headCenter && (headCenter.style.cssText = "");
		headLeft && (headLeft.style.cssText = "");
		revHead.style.cssText = "";
	}
}

//Mark 设置评论排序
function setRevSort() {
	const reviewsDom = v.isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
	// 点击空白区域，关闭排序
	const sort = $dqs(".head-sort,.tt-reviews-sort", reviewsDom);
	const sortPopup = $dqs(".sort-popup", reviewsDom);
	const active = $dqs(".sort-active", reviewsDom);
	window.addEventListener("click", function (e) {
		if (!sort.contains(e.target)) {
			sortPopup.style.display = "none";
		}
	});
	$dqsa(".sort-item", reviewsDom).forEach(
		i =>
			(i.onclick = function () {
				const reviewsDom = v.isWdoRevOpen ? v.wdoRevDom : v.reviewsDom;
				if (!this.classList.contains("active")) {
					$dqs(".sort-item.active", reviewsDom).classList.remove("active");
					this.classList.add("active");
					active.style.top = this.offsetTop + 3 + "px";
					const sortBy = this.getAttribute("sort-value");
					reviewsDom.setAttribute("review_sort_by", sortBy);
					if (v.userSetting.review_pagination_type === 1 && !v.isWdoRevOpen) {
						v.reviewsList = [];
						v.pageRecord = [];
					}
					sortPopup.style.display = "none";
					// QA开启时排序是否存在
					const sortText = $dqs(".tt-sort-content>span", reviewsDom);
					if (sortText) {
						// 存在则，每次修改排序都要改变文本
						sortText.textContent = this.textContent;
					}
					sgPage(3, 1);
					reviewsDom.setAttribute("reviews-page-no", 1);
					setReviewBody(true);
				}
			})
	);
	// 点击展示排序弹出框
	$dqs(".sort-icon,.tt-sort-content", reviewsDom).onclick = function () {
		let dis = sortPopup.style.display;
		sortPopup.style.display = dis === "block" ? "none" : "block";
	};
}
/**
 * 获取排序弹出框的html
 */
function sortPopupHtml() {
	const lang = v.lang;
	let html = "";

	let activeCls = "", // 是否当前排序
		activeText = ""; // 当前排序的文本
	// 所有排序方式
	const sorts = [
		"image-descending",
		"commented-at-descending",
		"rating-descending",
		"rating-ascending",
	];
	// 获取当前排序的索引
	const activeIndex = sorts.indexOf(v.userSetting.review_sort_by);
	sorts.forEach((it, index) => {
		activeCls = "";
		if (index === activeIndex) {
			// 设置当前排序激活状态，获取当前排序的文本
			activeCls = "active";
			activeText = lang.sort_by_options[index];
		}
		// 获取每项的html
		html += `<li index="${index}" sort-value="${it}" class="sort-item ${activeCls}">${lang.sort_by_options[index]}</li>`;
	});
	// 计算激活图标的显示位置
	const top = activeIndex * 35 + 54 + "px";
	// 返回数据
	return {
		text: activeText,
		html: `<div class="sort-popup">
	<ul class="sort-list">
		<li class="sort-title">${lang.sort_by}</li>
		${html}
	</ul>
	<div class="sort-active" style="top:${top}">${svg.hook()}
	</div>
</div>`,
	};
}

// Mark 首次运行后配置
function afterFirstRender() {
	const blockStyle = $dqs("#trustoo-block-style");
	if (blockStyle) {
		blockStyle.media = "all";
	}
	v.firstPageShow = true;
	v.isWdoRevOpen && (v.isWdoRevFirstPageRendered = true);
	// if (!v.isShowRevClick && v.isCssLoaded && v.reviewsDom) {
	if (!v.isShowRevClick && v.isCssLoaded && v.reviewsDom) {
		if (v.isGrid && v.layoutReviewCount > 15) {
			setTimeout(() => {
				v.reviewsDom.classList.remove("pending");
			}, 100);
		} else {
			v.reviewsDom.classList.remove("pending");
		}
	}

	// if (!v.isWdoRevOpen && v.isInsertRev) {
	// 	insertRevTab();
	// }
	// 开启QA,且不是全部评论区和弹窗评论区
	// if (v.userSetting.is_qa_active === 1 && !v.isWdoRevOpen && !v.isMixReview) {
	// 	setTimeout(() => {
	// 		if (!$dqs(".tt-qa-list", v.reviewsDom)) {
	// 			insertQA();
	// 		}
	// 	}, 500);
	// }
	// 运行完成后执行用户需求自定义函数
	if (typeof trustooAfterExecute !== "undefined") {
		trustooAfterExecute(v, u);
	}
}

/**
 * 设置评论区显示记录PV
 */
function setReviewShowObserver() {
	if (!v.RevIntersection && v.isProPage) {
		v.RevIntersection = new IntersectionObserver(entries => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					processRecordedData("reviews_view");
					v.RevIntersection.disconnect();
				}
			}
		}, {});
	}
	v.RevIntersection.observe(v.reviewsDom);
}
//Mark 监听tab评论区显示
function tabReviewObserve() {
	const reviewsNode = $dqs("#vstar-reviews");
	// 选择需要观察变动的节点
	if (reviewsNode && ResizeObserver) {
		const resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				const { width, height } = entry.contentRect;
				if (width && height) {
					smallWidRev();
					if (v.isGrid) {
						arrangeGrid();
						resizeObserver.disconnect();
					}
				}
			}
		});
		resizeObserver.observe(reviewsNode);
	}
}

//Mark 主题评论区宽度适配
function setReviewWidth() {
	let theme_store_id = Review.theme.theme_store_id;
	let extraClass = "";
	if (v.isProPage) {
		switch (theme_store_id) {
			/* 
		Dawn-887 Refresh-1567 Sense-1356 Crave-1363
		Craft-1368 Studio-1431 Taste-1434 Ride-1500
		Colorblock-1499 Debut-796 Venture-775 Narrative-829 
		*/
			case 887:
			case 1567:
			case 1356:
			case 1363:
			case 1368:
			case 1431:
			case 1434:
			case 1500:
			case 1499:
			case 796:
			case 775:
			case 829: {
				extraClass = "page-width";
				break;
			}
			/* 
		Brooklyn-730 Supply-679 Minimal-380
		*/
			case 730:
			case 679:
			case 380: {
				extraClass = "wrapper";
				break;
			}
			case 578: {
				//Simple
				extraClass = "site-wrapper";
				break;
			}
			default: {
				// Warehouse主题
				if (Review.theme.name === "warehouse") {
					v.reviewsDom.parentNode.classList.add("container");
					const cardNode = $dqs(".card--collapsed.card--sticky.card");
					if (cardNode) {
						v.reviewsDom.classList.add("card");
						v.globalStyle.textContent += `#vstar-reviews.card{border:none !important}`;
					}
				} else {
					if (!v.isMobile) {
						v.globalStyle.textContent += `#vstar-reviews{max-width:1200px;}`;
					}
				}
			}
		}
		if (extraClass != "") {
			const node = $dqs("." + extraClass);
			if (v.isNullRev && node) {
				const right = getComputedStyle(node).paddingRight;
				v.globalStyle.textContent += `#vstar-noreviews button.noreviews-button {right:${right}}`;
			}
			v.reviewsDom.classList.add(extraClass);
		}
	}
	// return wrapWid
}

// 瀑布流布局窗口大小变化适配
const updateLayout = debounce(() => {
	if (v.isGrid && v.isRevOpen) {
		arrangeGrid();
	}
}, 200);

//Mark 窗口变化动态变更
const updateRevWid = debounce(async () => {
	const reviewsDom = v.reviewsDom;
	const wrapWid = getReviewWidth();
	v.wrapWid = wrapWid;
	let tarWid = 0;
	if (v.revTar) {
		tarWid = parseInt(getComputedStyle(v.revTar).width);
		v.revTarWid = tarWid;
	}
	!v.isMixReview && smallWidRev();
	if (wrapWid != 0) {
		if (tarWid < 678 && tarWid !== 0) {
			reviewsDom.style.width = v.revTarWid + "px";
		} else reviewsDom.style.width = wrapWid + "px";
	}
}, 100);

export {
	getReviews,
	getEmptyReview,
	updateLayout,
	updateRevWid,
	setReviewShowObserver,
	sortPopupHtml,
	handleHashchange,
};
