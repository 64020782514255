const reviewsNodes = {
	normal: {
		curBody: null,
		normalReviews: null,
		shopReviews: null,
	},
	popup: {
		curBody: null,
		normalReviews: null,
		shopReviews: null,
	},
	write: {
		container: null,
		allInOne: null,
		image: null,
		prodName: null,
		title: null,
		contentLabel: null,
		submit: null,
	},
	mask: null,
};

export default reviewsNodes;
