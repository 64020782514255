import reviewsNodes from "../common/node";
import { $dqs, $dqsa } from "../common/utils";
import v from "../common/variable";

let gap = 0,
	startNo = 0;
let spacing;
if (v.isMobile) {
	spacing = 18;
} else {
	spacing = 16;
}
let isOneRwo = false;

//Mark瀑布流列高度更新 Show full review 点击、或重叠重排
function UpdateGridColumnHeight(index, cardId) {
	const { isWdoRevOpen } = v;
	let reviews = isWdoRevOpen ? v.wdoRev : v.reviews;
	const columnHighs =
		v.showType === "shopReviews"
			? reviews.shopReviewsHighs
			: reviews.columnHighs;
	const listWrapper = reviewsNodes[v.reviewsKey].curBody;

	const targetCart = $dqs(`[no="${cardId}"]`, listWrapper);

	var currentTop = parseInt(targetCart.style.top);
	var currentHeight = targetCart.offsetHeight + currentTop + spacing;
	const msg = index + "column's hight updates,hight is " + currentHeight;
	Review.checkGridHighMsg.push(msg);
	if (currentHeight > columnHighs[index - 1]) {
		columnHighs[index - 1] = currentHeight;
	}
	let columnNodeList = $dqsa(`.column${index}`, listWrapper);
	columnNodeList.forEach((item, inx) => {
		const num = parseInt(item.getAttribute("no"));
		if (num > cardId) {
			item.style.top = currentHeight + "px";
			currentHeight = currentHeight + item.offsetHeight + spacing;
		}
		if (inx === columnNodeList.length - 1) {
			columnHighs[index - 1] = currentHeight;
		}
	});
	let h = 30;
	if (v.isMobile) {
		h = 0;
	}
	var maxHeight = Math.max.apply(null, columnHighs);
	listWrapper.style.height = maxHeight + h + "px";
}

//Mark 瀑布流添加新卡片
function addGridNewCards(cards = [], startCardNo, isInitHigh = false) {
	const { isWdoRevOpen } = v;
	const reviews = v.isWdoRevOpen ? v.wdoRev : v.reviews;
	const columnHighs =
		v.showType === "shopReviews"
			? reviews.shopReviewsHighs
			: reviews.columnHighs;
	const reviewsBody = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;
	const listWrapper = reviewsNodes[v.reviewsKey].curBody;

	if (!isWdoRevOpen && reviewsBody.offsetParent === "none") return;
	startNo = startCardNo;

	if (isInitHigh) {
		for (let i = 0; i < columnHighs.length; i++) {
			columnHighs[i] = 0;
		}
	}

	// 该评论区未渲染过瀑布流，列高度数组没有数据
	if (columnHighs.length === 0) {
		changeGrid(listWrapper, cards);
	} else {
		setCardsWidth(cards, reviews.cardWid);
	}

	const cardWid = reviews.cardWid;
	cards.forEach(i => {
		const n = $dqs(".resource-item", i);
		if (n !== null) {
			const p = n.getAttribute("proportion");
			n.style.width = cardWid + "px";
			$dqs("img", n).width = cardWid;
			n.style.height = cardWid / p + "px";
			$dqs("img", n).height = "auto";
		}
	});

	updateGridHigh(listWrapper, cards, true);
	isOneRwo = cards.length <= columnHighs.length && v.isFirstPageRender;
	if (v.isCssLoaded) {
		setTimeout(() => {
			checkGridHigh(listWrapper);
		}, 200);
	}
	const ttStyle = $dqs('link[href*="trustoo-reviews.min.css"]');
	if (ttStyle.getAttribute("media") !== "all") {
		ttStyle.addEventListener("load", () => arrangeGrid());
	}
}

// Mark 排列瀑布流
function arrangeGrid() {
	const { isWdoRevOpen } = v;
	const reviewsBody = isWdoRevOpen ? v.wdoRevBody : v.reviewsBody;
	if (!isWdoRevOpen && reviewsBody.offsetParent === "none") return;
	const listWrapper = reviewsNodes[v.reviewsKey].curBody;

	$dqsa("[new-grid-img]", reviewsBody).forEach(i => {
		i.removeAttribute("style");
		i.removeAttribute("new-grid-img");
	});
	const cards = Array.from($dqsa(".grid-review", listWrapper));
	changeGrid(listWrapper, cards);
	updateGridHigh(listWrapper, cards);
}
v.arrangeGrid = arrangeGrid;

//Mark 瀑布流布局列数、卡片宽度变化
function changeGrid(listWrapper, cards) {
	const { isWdoRevOpen } = v;
	const reviews = isWdoRevOpen ? v.wdoRev : v.reviews;
	const styles = window.getComputedStyle(listWrapper);

	let reviewsWidth = parseInt(styles.width);
	//  - parseInt(styles.paddingLeft) * 2;
	Review.reviewsWidth = reviewsWidth;
	// if (reviewsWidth < 501) {
	// 	gap = 16;
	// }

	const column = getGridColumnAndWidth(reviewsWidth, listWrapper);
	// reviews.column = column

	const columnHighs =
		v.showType === "shopReviews"
			? reviews.shopReviewsHighs
			: reviews.columnHighs;
	columnHighs.length = 0;
	for (let i = 0; i < column; i++) {
		columnHighs.push(0);
	}

	// cardWid = isWdoRevOpen ? v.wdoGridCardWidth : v.gridCardWidth
	setCardsWidth(cards, reviews.cardWid);
}

//Mark 瀑布流高度更新
function updateGridHigh(listWrapper, cards) {
	const { isWdoRevOpen } = v;
	let reviews = isWdoRevOpen ? v.wdoRev : v.reviews;
	const columnHighs =
		v.showType === "shopReviews"
			? reviews.shopReviewsHighs
			: reviews.columnHighs;
	const preColHighs = columnHighs.map(i => i);
	if (v.showType === "shopReviews") {
		reviews.preShopReviewsHighs = preColHighs;
	} else {
		reviews.preColHighs = preColHighs;
	}

	for (var i = 0; i < cards.length; i++) {
		const curCard = cards[i];
		var index = 0;
		let minHeight = Math.min.apply(null, columnHighs);
		index = columnHighs.findIndex(item => item === minHeight);
		columnHighs[index] = columnHighs[index] + curCard.offsetHeight + spacing;
		curCard.className = `grid-review column${index + 1}`;
		curCard.dataset.column = index + 1;
		curCard.style.left = index * (reviews.cardWid + 12) + gap + "px";
		curCard.style.top = minHeight + "px";

		v.minHeight = minHeight;
	}
	var maxHeight = Math.max.apply(null, columnHighs);
	let BodyHigh = "";
	if (!v.isMobile) {
		BodyHigh = `${maxHeight + 30}px`;
	} else {
		BodyHigh = `${maxHeight}px`;
	}
	listWrapper.style.height = BodyHigh;
}

// 设置卡片宽度
function setCardsWidth(cards, cardWid) {
	cards.forEach(i => {
		i.style.width = cardWid + "px";
		if ($dqs("img", i)) $dqs("img", i).width = cardWid;
	});
}

//Mark 瀑布流获取列数和宽度
function getGridColumnAndWidth(revWid, reviewsBody) {
	const reviews = v.isWdoRevOpen ? v.wdoRev : v.reviews;
	let column = 0;
	let gap = 0;
	// if (bodyPadding !== 0) {
	// 	gap = 32;
	// } else {
	// 	if (!v.isMobile && revWid < 678) {
	// 		gap = 32;
	// 	}
	// }
	if (revWid >= 280 && revWid < 501) {
		column = 2;
		// gap = 32;
	} else if (revWid >= 501 && revWid < 920) {
		column = 3;
	} else if (revWid >= 920 && revWid < 1150) {
		column = 4;
	} else if (revWid >= 1150 && revWid < 1420) {
		column = 5;
	} else if (revWid >= 1420) {
		column = 6;
	}
	if (!v.isMobile) {
		if (column === 2 || column === 3) {
			$dqsa(".reviews-date", reviewsBody).forEach(
				i => (i.style.cssText = "position: static;")
			);
			$dqsa(".grid-mobile", reviewsBody).forEach(
				i => (i.style.cssText = "margin-top: 10px;")
			);
		} else if (column > 0) {
			$dqsa(".reviews-date", reviewsBody).forEach(i => (i.style.cssText = ""));
			$dqsa(".grid-mobile", reviewsBody).forEach(i => (i.style.cssText = ""));
		}
	}
	reviews.cardWid = (revWid - ((column - 1) * 12 + gap)) / column;
	return column;
}

// Mark 瀑布流高度检验
function checkGridHigh(listWrapper, loadedStyleCheck = false) {
	// console.log('高度检验');
	const checkMsg = { highMsg: [] };
	let reviews = v.isWdoRevOpen ? v.wdoRev : v.reviews;
	const preColHighs =
		v.showType === "shopReviews"
			? reviews.preShopReviewsHighs
			: reviews.preColHighs;
	const column = preColHighs.length;
	checkMsg.loadedStyleCheck = loadedStyleCheck;
	// console.log('高度检验,列数为', column);
	if (column === 0) {
		if (loadedStyleCheck) {
			return;
		}
		arrangeGrid();
	} else {
		for (let i = 0; i < column; i++) {
			// console.log('第' + (i + 1) + '列高度检验');
			const columnCards = $dqsa(".grid-review.column" + (i + 1), listWrapper);
			// 一行时判断body高度是否出现问题
			// console.log('isOneRwo', isOneRwo);
			if (isOneRwo && columnCards[0]) {
				let h = 30;
				if (v.isMobile) {
					h = 0;
				}
				if (
					parseInt(listWrapper.style.height) - h <
					columnCards[0].offsetHeight
				) {
					const msg = {
						errColumn: i + 1,
						cardNo: 1,
						highDiff: `One row with the wrong height`,
					};
					const no = columnCards[0].getAttribute("no");
					checkMsg.highMsg.push(msg);
					UpdateGridColumnHeight(i + 1, no);
					// break;
				}
			} else {
				let curHigh = preColHighs[i];
				let curTop = 0;
				const newCards = [];
				columnCards.forEach(i => {
					const no = i.getAttribute("no");
					if (no > startNo) {
						newCards.push(i);
					}
				});
				if (newCards.length === 0) {
					continue;
				}
				curTop = curHigh + newCards[0].offsetHeight + spacing;
				for (let j = 1; j < newCards.length; j++) {
					const msg = {};
					const cart = newCards[j];
					if (parseInt(cart.style.top) !== curTop) {
						const no = newCards[j - 1].getAttribute("no");
						msg.errColumn = i + 1;
						msg.cardNo = no;
						msg.highDiff = `nowHigh:${parseInt(
							cart.style.top
						)},Ideal height:${curTop} `;
						checkMsg.highMsg.push(msg);

						UpdateGridColumnHeight(i + 1, no);
						// break;
					}
					curTop = curTop + cart.offsetHeight + spacing;
				}
			}
		}
	}
	Review.checkGridMsg.push(checkMsg);
}

export {
	UpdateGridColumnHeight,
	arrangeGrid,
	changeGrid,
	addGridNewCards,
	updateGridHigh,
	checkGridHigh,
};
